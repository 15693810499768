const initialState = {
    SupplierPicker: [],
    SupplierMaterials: [],
    SupplierPaymentTerm: [],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "SUPPLIER_PICKER":
            //console.log("SUPPLIER_PICKER", action.value);
            newstate.SupplierPicker = action.value;
            return {
                ...newstate,
                SupplierPicker: [...newstate.SupplierPicker],
            };

        case "GET_SUPPLIER_PAYMENT_TERM":
            newstate.SupplierPaymentTerm = action.value;
            return {
                ...newstate,
                SupplierPaymentTerm: [...newstate.SupplierPaymentTerm],
            };

        case "SUPPLIER_GET_MATERIALS":
            //console.log("SUPPLIER_GET_MATERIALS", action.value);
            newstate.SupplierMaterials = action.value;
            return {
                ...newstate,
                SupplierMaterials: [...newstate.SupplierMaterials],
            };

        default:
            break;
    }

    return state;
};
export default reducer;
