const initialState = {
    SupplierPicker: [],
    SupplierMaterials: [],
    SupplierPaymentTerm: [],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "COMMISSION_DATA":
            newstate.SupplierPicker = action.value;
            return {
                ...newstate,
                SupplierPicker: [...newstate.SupplierPicker],
            };

        default:
            break;
    }

    return state;
};
export default reducer;
