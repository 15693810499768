import React from "react";
import Loader from "react-loader-spinner";
import "./laoding.css";

export const loading = () => (
  <div
    style={{
      marginTop: "40vh",
      width: "100%",
      height: "100",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Loader
      type="Triangle"
      color="#ade2e6"
      height={200}
      width={200}
      //timeout={3000} //3 secs
    />
  </div>
);

export const loadingTableData = () => (
  <div>
    <Loader
      type="Triangle"
      color="#ade2e6"
      height={200}
      width={200}
      //timeout={3000} //3 secs
    />
  </div>
);

export const loadingPage = () => (
  <div
    className="animated fadeIn"
    style={{
      marginTop: "40vh",
      width: "100%",
      height: "100",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Loader
      type="Rings"
      color="#ade2e6"
      height={200}
      width={200}
      //timeout={3000} //3 secs
    />
  </div>
);
