import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middleware = [thunk];
var store = null;

if (process.env.NODE_ENV === "production") {
    compose(applyMiddleware(...middleware));

    const configStore = createStore(
        rootReducer,
        compose(applyMiddleware(...middleware))
    );

    store = configStore;
} else if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    const configStore = createStore(
        rootReducer,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
                window.__REDUX_DEVTOOLS_EXTENSION__({
                    trace: true,
                    traceLimit: 25,
                })
        )
    );
    store = configStore;
} else {
    const configStore = createStore(
        rootReducer,
        compose(
            applyMiddleware(...middleware)
        )
    );
    store = configStore;
}

export default store;
