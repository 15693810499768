import axios from "axios";
import { returnErrors } from "./errorActions";
import { toast } from "react-toastify";

// test change
// Show users
export const showusers = () => (dispatch) => {
    dispatch({
        type: "DATA-LOADING",
    });
    axios
        .get("/api/users/users")
        .then((res) => {
            dispatch({
                type: "USER_SHOWED",
                value: res.data,
            });
            dispatch({
                type: "DATA-LOADED",
            });
        })
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            dispatch({
                type: "DATA-LOADED",
            });

            console.log("show user error! : " + err);
        });
};

export const getUserGroup = () => (dispatch) => {
    axios
        .get("/api/users/group")
        .then((res) =>
            dispatch({
                type: "GET_USER_GROUP",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("show user roles error! : " + err);
        });
};

//updateUserGroup
export const updateUserGroup = ({ id, name }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({ id, name });

    axios
        .put("/api/users/updateUserGroup", body, config)
        .then((res) => {
            toast.success("User Group Updated Successfully!");
            dispatch(getUserGroup());
        })
        .catch((err) => {
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_USER_GROUP_UNSUCCESS"
                )
            );
            console.log(err);
        });
};

//update program
export const updateProgram = ({ id, name, url }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({ id, name, url });
    console.log(JSON.stringify(body));
    axios
        .put("/api/users/updateProgram", body, config)
        .then((res) => {
            toast.success("Program Updated Successfully!");
            dispatch(getProgram());
        })
        .catch((err) => {
            toast.error("Update Program Failed!");
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_PROGRAM_UNSUCCESS"
                )
            );
            console.log(err);
        });
};

//update program
export const updateMenu = ({
    MenuId,
    MenuName,
    programId,
    levelId,
    ParentId,
    parentIndex,
    childIndex,
    icon,
}) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        MenuId,
        MenuName,
        programId,
        levelId,
        ParentId,
        parentIndex,
        childIndex,
        icon,
    });
    console.log(JSON.stringify(body));
    axios
        .put("/api/users/updateMenu", body, config)
        .then((res) => {
            toast.success("Menu Updated Successfully!");
            dispatch({
                type: "MENU_UPDATED",
            });
            dispatch(getMenu());
            dispatch({
                type: "CHANGE_MENU_UPDATED",
            });
        })
        .catch((err) => {
            console.log(err);
            toast.error("Menu Update Failed!");
            dispatch({
                type: "MENU_UPDATE_FAILED",
            });
        });
};
//updatemenulevel
export const updateMenulevel = ({ id, Menulevel, MenulevelName }) => (
    dispatch
) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log(id, Menulevel, MenulevelName);
    // Request body
    const body = JSON.stringify({
        id,
        Menulevel,
        MenulevelName,
    });

    axios
        .put("/api/users/updateMenuLevel", body, config)
        .then((res) => {
            toast.success("MenuLevel Data Updated Successfully!");
            dispatch(getMenuLevel());
        })
        .catch((err) => {
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_MENULEVEL_DATA_UNSUCCESS"
                )
            );
            console.log(err);
        });
};

export const getUserRoles = () => (dispatch) => {
    axios
        .get("/api/users/roles")
        .then((res) =>
            dispatch({
                type: "GET_USER_ROLES",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("show user roles error! : " + err);
        });
};

//updateUserRole
export const updateUserRoles = ({ id, name }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({ id, name });

    dispatch({
        type: "UPDATE_ROLE_LOADING",
    });
    axios
        .put("/api/users/updateUserRoles", body, config)
        .then((res) => {
            dispatch({
                type: "USER_ROLE_UPDATED",
            });
            dispatch(getUserRoles());
            dispatch({
                type: "CHANGE_USER_ROLES_UPDATE_STATUS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "UPDATE_USER_ROLES_UNSUCCESS",
            });
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_USER_ROLES_UNSUCCESS"
                )
            );
            console.log(err);
        });
};

//delete user Role
export const deleteUserRole = ({ id }, state, reason, callback) => (
    dispatch
) => {
    axios
        .delete(`/api/users/deleteUserRole/${id}`)
        .then((res) => {
            dispatch({
                type: "USER_ROLE_DELETED",
                value: id,
            });
            toast.success("User Role Deleted Successfully!");
            callback(null);
        })
        .catch((err) => {
            console.log("show userRole error! : " + err);
            callback(err.response.data);
        });
};

//updateUser
export const updateUser = (data) => (dispatch) => {
    console.log(data);
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    // const body = JSON.stringify({
    //   id,
    //   name,
    //   username,
    //   userGroupId,
    //   viewLevelId,
    //   WarehouseID
    // });

    axios
        .put("/api/users/updateUser", data, config)
        .then((res) => {
            dispatch(showusers());
            toast.success("User Updated Successfully!");
        })
        .catch((err) => {
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_USER_UNSUCCESS"
                )
            );
            console.log(err);
        });
};

//delete user
export const deleteUser = ({ value }, state, reason, callback) => (
    dispatch
) => {
    axios
        .delete(`/api/users/delete/${value}`)
        .then((res) => {
            dispatch(showusers());
            toast.success("User Deleted Successfully!");
            // dispatch(getUser)
            callback(null);
        })
        .catch((err) => {
            toast.error("Couldn't able to delete user!");
            console.log("show user error! : " + err);
            callback(err.response.data);
        });
};

//delete user group
export const deleteUserGroup = ({ id }, state, reason, callback) => (
    dispatch
) => {
    console.log("action id : " + id + "");
    axios
        .delete(`/api/users/deleteUserGroup/${id}`)
        .then((res) => {
            dispatch({
                type: "USER_GROUP_DELETED",
                value: id,
            });
            toast.success("User Group Deleted Successfully!");
            callback(null);
        })
        .catch((err) => {
            console.log("show user error! : " + err);
            callback(err.response.data);
        });
};

//get assigned roles
export const getAssignedRoles = (id) => (dispatch) => {
    console.log("Fronend id : " + id);

    axios
        .get(`/api/users/getAssignedRoles/${id}`)
        .then((res) =>
            dispatch(
                {
                    type: "GET_ASSIGNED_ROLES",
                    value: res.data,
                },
                console.log(res.data)
            )
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("Get Assigned roles error! : " + err);
        });
};

//Add assigned roles
export const addAssignedRoles = (name) => (dispatch) => {
    dispatch({
        type: "ADD_ASSIGNED_ROLES",
        value: name,
    });
};

//delete user assigned roles
export const deleteAssignedRoles = ({ roleIDs, id }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({ id, roleIDs });

    axios
        .post("/api/users/deleteAssignedRoles", body, config)
        .then((res) => {
            dispatch(getAssignedRoles(id));
            toast.success("Assigned Role Updated Successfully!");
        })
        .catch((err) => {
            console.log(err);
            toast.error("Role Assignment Failed!");
        });
};

//delete Role permission map
export const deleteRolePermissionMap = (
    PermissionId,
    id,
    ProgramId,
    Prompt2
) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({ PermissionId, id, ProgramId, Prompt2 });
    axios
        .post("/api/users/deleteRolePermission", body, config)
        .then((res) => {
            dispatch({
                type: "ROLE_PERMISSION_DELETED",
                value: id,
            });
            toast.success("Role Permission Deleted Successfully!");
        })
        .catch((err) => {
            console.log(err);
        });
};

//delete User permission
export const deleteUserPermission = (data, state, reason, callback) => (
    dispatch
) => {
    console.log("data at deleteUserPermission: " + JSON.stringify(data));
    console.log("state at deleteUserPermission: " + JSON.stringify(state));

    const userPermissionId = data.userPermissionId;
    const userId = data.userid;

    axios
        .delete("/api/users/deleteUserPermission/" + userPermissionId, {
            state,
        })
        .then((res) => {
            dispatch({
                type: "USER_PERMISSION_DELETED",
                value: data.userPermissionId,
            });
            dispatch(getUserPermission(userId, state));
            toast.success("User Permission Deleted Successfully!");
            callback(null);
        })
        .catch((err) => {
            console.log(err);
            callback(err.response.data);
        });
};

//get Programme data
export const getProgram = () => (dispatch) => {
    axios
        .get(`/api/users/getProgram`)
        .then((res) =>
            dispatch({
                type: "GET_PROGRAM",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("Get Program error! : " + err);
        });
};

//get User Permission
export const getUserPermission = (id, state = "") => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    axios
        .post("/api/users/getUserPermission/" + id, { state }, config)

        .then((res) =>
            dispatch({
                type: "GET_USER_PERMISSION",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("Get Program error! : " + err);
        });
};

//get Selected user data
export const sendUserData = (data) => (dispatch) => {
    dispatch({
        type: "SET_USER_DATA",
        value: data,
    });
};

//get selected role data
export const sendRoleData = (data) => (dispatch) => {
    dispatch({
        type: "SET_ROLE_DATA",
        value: data,
    });
};

export const containUserName = (data) => (dispatch) => {
    dispatch({
        type: "GET_USER_DETAILS_FOR_PERMISSION",
        value: data,
    });
};

export const containRoleName = (data) => (dispatch) => {
    dispatch({
        type: "GET_ROLE_DETAILS_FOR_PERMISSION",
        value: data,
    });
};
//get User Permission
export const getUserNamesForPermission = () => (dispatch) => {
    axios
        .get(`/api/users/getUserNamesForPermission`)
        .then((res) =>
            dispatch({
                type: "GET_USER_NAMES_FOR_PERMISSION",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("Get user paermission error! : " + err);
        });
};
//get Menu
export const getMenu = () => (dispatch) => {
    axios
        .get(`/api/users/getMenu`)
        .then((res) =>
            dispatch({
                type: "GET_MENU",
                value: res.data,
            })
        )
        .catch((err) => {
            console.log("Get Menu error! : " + err);
        });
};

//get Role Permission mapping
export const getRoleMapping = (id, state = "") => (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    axios
        .get(`/api/users/GetRolePermission/` + id, { state }, config)
        .then((res) =>
            dispatch({
                type: "GET_ROLE_PERMISSION_MAP",
                value: res.data,
            })
        )
        .catch((err) => {
            console.log("Get Role Permission Map error! : " + err);
        });
};

//get roles names for Permission mapping
export const getRoleNamesForPermission = () => (dispatch) => {
    axios
        .get(`/api/users/getRoleNamesForPermission`)
        .then((res) =>
            dispatch({
                type: "GET_ROLE_NAMES_FOR_PERMISSION",
                value: res.data,
            })
        )
        .catch((err) => {
            //   dispatch(
            //     returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
            //   );
            console.log("Get user paermission error! : " + err);
        });
};

//delete user group
export const deleteProgram = ({ id }, state, reason, callback) => (
    dispatch
) => {
    axios
        .delete(`/api/users/deleteProgram/${id}`)
        .then((res) => {
            dispatch({
                type: "PROGRAM_DELETED",
                value: id,
            });

            toast.success("Program Deleted Successfully!");
            callback(null);
        })
        .catch((err) => {
            console.log("Program show error! : " + err);
            toast.error("Delete Failed!");
            callback(err.response.data);
        });
};

//delete Menu
export const deleteMenu = (id, state, reason, callback) => async (dispatch) => {
    await axios
        .delete(`/api/users/deleteMenu/${id}`)
        .then((res) => {
            dispatch({
                type: "MENU_DELETED",
                value: { id },
            });
            dispatch(getMenu(state));
            toast.success("Menu Deleted Successfully!");
            callback(null);
        })
        .catch((err) => {
            toast.error("Delete Failed!");
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "MENU_DELETE_FAILED"
                )
            );
            console.log(err);
            callback(err.response.data);
        });
};

// delete menu level
export const deleteMenuLevel = (id) => (dispatch) => {
    console.log("id is : " + id);
    axios
        .delete(`/api/users/deleteMenulevel/${id}`)
        .then((res) => {
            dispatch({
                type: "MENULEVEL_DELETED",
                value: id,
            });

            toast.success("Menu Level Deleted Successfully!");
            dispatch(getMenuLevel());
        })
        .catch((err) => {
            // dispatch(
            //           returnErrors(
            //             err.response.data,
            //             err.response.status,
            //             "MENULEVEL_DELETE_FAILED"
            //           )
            //         );
            toast.error(err.response.data);
        });
};

//get Menu level
export const getMenuLevel = () => (dispatch) => {
    axios
        .get(`/api/users/getMenuLevel`)
        .then((res) =>
            dispatch(
                {
                    type: "GET_MENU_LEVEL",
                    value: res.data,
                },
                console.log(res.data)
            )
        )
        .catch((err) => {
            console.log("Get Menu Level error! : " + err);
        });
};

//update RolePermiision mapping
export const updateRolePermissionMap = (
    { id, PermissionId, roleId, programId, c, r, u, d },
    Prompt,
    state
) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        Prompt,
        id,
        PermissionId,
        roleId,
        programId,
        c,
        r,
        u,
        d,
    });

    axios
        .put("/api/users/updateRolePermissionMap", body, config)
        .then((res) => {
            dispatch({
                type: "UPDATE_ROLE_MAPPING_SUCCESS",
            });

            dispatch(getRoleMapping(roleId, state));
            toast.success("Role Permissions Updated Successfully!");

            dispatch({
                type: "UPDATE_CHANGE_ROLE_MAPPING_SUCCESS",
            });
        })

        .catch((err) => {
            dispatch({
                type: "UPDATE_ROLE_MAPPING_FAIL",
            });

            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_ROLE_MAPPING_FAIL"
                )
            );
            console.log(err);
        });
};

//update User Permiision
export const updateUserPermission = (
    { PermissionId, UserId, programId, c, r, u, d, s },
    state
) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        PermissionId,
        UserId,
        programId,
        c,
        r,
        u,
        d,
        s,
    });
    console.log(JSON.stringify(body));
    axios
        .put("/api/users/updateUserPermission", body, config)
        .then((res) => {
            dispatch(getUserPermission(UserId, state));
            toast.success("User Permissions Updated Successfully!");
        })

        .catch((err) => {
            dispatch(
                returnErrors(
                    err.response.data,
                    err.response.status,
                    "UPDATE_USER__PERMISSION_ERROR"
                )
            );
            console.log(err);
        });
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
    // Get token from localstorage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json",
        },
    };

    // If token, add to headers
    if (token) {
        config.headers["x-auth-token"] = token;
    }

    return config;
};

export const copyUserPermission = (copyUserId, copyUserName , UserId , UserName) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({copyUserId, copyUserName , UserId , UserName });

    axios
        .post("/api/users/copyUserPermission",body ,config)
        .then((res)=>{
            toast.success("User Permissions Updated Successfully!");
        })
        .catch((err)=>{
            toast.error("User Permissions Update failed!");
        })
};
