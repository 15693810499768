const initialState = {
    AddCusStatus: false,
    AddCusLoadingStatus: false,
    UpdateCusLoadingStatus: false,
    CustomerUpdateStatus: false,

    CustomerData: null,
    CustomerDataPageSize: 0,
    CustomerDataForInvoice: null,
    CustomerOBJECT: null,
    CustomerOBJ: null,
    salerepData: null,
    salerepDataONLY: [],
    AddToAddressData: null,
    CusAddressStatus: false,
    CusStakeAddressStatus: false,
    CusStakeAddressLoadingStatus: false,
    CusStakeAddressOBJECT: [],
    cusAddressData: null,
    CusConPerData: [],
    CusConPerDataOnly: [],
    CusConPerStatus: false,
    CusConPerOBJECT: null,
    cusAddressSearchedData: [],
    cusAddressDataMerged: null,
    CustomerRePreOBJECT: null,
    addsalsesRepresentative: false,
    getsalsesRepresentative: false,
    updaterepresentative: false,
    assignedItemsOriginal: [],
    assignedItems: [],
    AssignedItem: [],
    assignedItemsAll: null,
    assignedItemsAllPageSize: 0,
    assignedItemsLoded: false,
    assignedItemsUpdated: false,
    ItemAssignedStatus: false,
    ItemObject: [],
    AddItemAssignedLoadingStatus: false,
    AssignedItemUpdateStatus: false,
    AssignedItemUpdateLoadingStatus: false,
    CustomerAddedStatus: false,
    addViewlevelStatus: false,
    getViewLeveldata: null,
    getblockedCustomers: null,
    getblockedCustomersDetails: [],
    addUnblockORInactiveCustomerStatus: false,
    DocumentData: null,
    AddDocumentStatus: false,
    AddDocumentLoadingStatus: false,
    getInactiveSuppliers: [],
    getSuppliersByTotalDue: [],
    getSupplierActivationDetails: [],
    getSupplierDatapageSize: 0,
    getSupplierLineData: null,
    supplierdataToUpdate: null,
    supplierdataToUpdateLocation: null,
    checkSupplierdata: false,
    checkingSupplierStatus: false,
    addSupplierDocLoading: false,
    addSupplierDocStatus: false,
    temporarySupplierDocdata: null,
    supDocsLink: null,
    supDocdata: [],
    addSuplliersdata: null,
    SwapMnyModalState: false,
    SwapMnyTransferModalState: false,
    FromAccount: "",
    TransferAccount: "",
    SwapMnyLoadinglState: false,
    getDocDataToUpdate: null,
    saveSupplierId: null,
    supplierDocsLoading: false,
    addDocumentStatus: false,
    supplierRawMaterialToInsert: [],
    supplierSurchargeTypeToInsert: [],
    supplierDistributionItemToInsert: [],
    supplierPurchaseServiceToInsert:[],
    currentarray: [],
    updatedArray: [],
    data1: [],
    getDocsByID: null,
    getAssignRawMaterialdataofCustomers: null,
    getAssignDistributionItemdataofSuppliers: null,
    loadingAssinfrawmaterialTosuppliers: null,
    loadingAssinDistrutionItemTosuppliers: false,
    adiingDistributionItemtoSupplier: false,
    adiingrawmaterialtoSupplier: null,
    loadingUpdateAssinedDisItemsTosuppliers: false,
    UpdateDistributionItemstoSupplier: false,
    loadingUpdateRawMaterialTosuppliers: false,
    UpdateRawMaterialtoSupplier: false,
    deleteAssignRawmaterial: false,
    SupConPerOBJECT: null,
    getSupplierContactPersonData: null,
    tempararySupplierContactPerson: null,
    insertAllSupplierData: false,
    getRawmaterialDOCsdata: null,
    getSupAssignedDisItemDOCsdata: null,
    getProductionStepsPages: null,
    getProductionSteps: null,
    getwebuserdataPages: 0,
    getwebuserdata: null,
    webUserdataToUpdate: null,
    getWebUserAddressdata: null,
    addAddressToWebUser: false,
    loadingWebUserAddressdata: false,
    loadingWebUserupdatedAddressdata: false,
    webAdreessUpdateStatus: false,
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "RESET_INVOICE":
            return {
                ...newstate,
                cusAddressDataMerged: null,
            };
        case "ADD_CUSTOMER_SUCCESS":
            return {
                ...state,
                AddCusStatus: true,
                CustomerOBJECT: action.value,
                AddCusLoadingStatus: false,
            };
        case "ADD_CUSTOMER_FOR_ERRORS_LOADING":
            return {
                ...state,
                AddCusLoadingStatus: true,
            };
        case "ADD_CUSTOMER_FAIL":
            return {
                ...state,
                AddCusStatus: false,
                AddCusLoadingStatus: false,
            };
        case "CHANGE_CUSTOMER_STATE":
            return {
                ...state,
                AddCusStatus: false,
            };

        case "UPDATE_CUSTOMER_LOADING":
            return {
                ...state,
                UpdateCusLoadingStatus: true,
            };
        case "UPDATE_CUSTOMER_LOADING_SUCCESS":
            return {
                ...state,
                UpdateCusLoadingStatus: false,
            };
        case "UPDATE_CUSTOMER_LOADING_FAIL":
            return {
                ...state,
                UpdateCusLoadingStatus: false,
            };

        case "GET_CUSTOMER_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    CustomerData: action.value.rows,
                    CustomerDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    CustomerData: action.value,
                };
            }

        case "GET_CUSTOMER_DATA_FOR_INVOICE":
            return {
                ...state,
                CustomerDataForInvoice: action.value,
            };
        case "RESET_CUSTOMER_DATA_FOR_INVOICE":
            return {
                ...state,
                CustomerDataForInvoice: null,
            };
        case "ADD_CUS_OBJ":
            return {
                ...state,
                CustomerOBJ: action.value,
            };
        case "RESET_CUS_OBJ":
            return {
                ...state,
                salerepDataONLY: [],
                salerepData: null,
                assignedItemsAll: null,
                CusConPerData: [],
                CusConPerDataOnly: [],
                cusAddressData: null,
            };
        case "CHANGE_CUSTOMER_UPDATE_STATE":
            return {
                ...state,
                CustomerUpdateStatus: false,
            };
        case "CUSTOMER_UPDATED":
            return {
                ...state,
                CustomerUpdateStatus: true,
            };

        case "UPDATE_CUSTOMER_UNSUCCESS":
            return {
                ...state,
                CustomerUpdateStatus: false,
            };

        case "CUSTOMER_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                CustomerData: state.CustomerData.filter((Cus) => Cus.id !== action.value),
            };
        case "CUSTOMER_DEACTIVATED":
            console.log("ID I GOT " + action.value);

            return {
                ...state,
                CustomerData: state.CustomerData.filter((Cus) => Cus.id !== action.value),
            };
        case "ADD_STAKEHOLDER_ADDRESS_SUCCESS":
            let CusStakeAddressOBJECT = [];
            if (state.CusStakeAddressOBJECT.length > 0) {
                CusStakeAddressOBJECT = [...state.CusStakeAddressOBJECT];
                if (action.value[1].length > 0) {
                    CusStakeAddressOBJECT = [...action.value[1]];
                }
            }

            console.log("action.value " + JSON.stringify(action.value[0]));
            console.log("action.value[1] " + JSON.stringify(action.value[1]));
            CusStakeAddressOBJECT.push(action.value[0]);

            return {
                ...state,

                CusStakeAddressOBJECT,
                AddToAddressData: null,
                CusStakeAddressLoadingStatus: false,
            };

        case "DELETE_STAKEHOLDER_ADDRESS":
            let CusStakeAddressOBJECTData = [...state.CusStakeAddressOBJECT];
            CusStakeAddressOBJECTData.splice(action.value, 1);
            return {
                ...state,

                CusStakeAddressOBJECT: CusStakeAddressOBJECTData,
            };

        case "ADD_STAKEHOLDER_ADDRESS_FAIL":
            return {
                ...state,
                CusStakeAddressStatus: false,
                CusStakeAddressOBJECT: [],
                CusStakeAddressLoadingStatus: false,
            };
        case "ADD_CUS_ADDRESS_LOADING":
            return {
                ...state,
                CusStakeAddressLoadingStatus: true,
            };
        case "CHANGE_STAKEHOLDER_ADDRESS_STATE":
            return {
                ...state,
                CusStakeAddressStatus: false,
            };

        case "ADD_TO_ADDRESS":
            return {
                ...state,
                AddToAddressData: action.value,
            };

        case "ADD_CUSTOMER_ADDRESS_SUCCESS":
            return {
                ...state,
                CusAddressStatus: true,
            };
        case "ADD_CUSTOMER_ADDRESS_FAIL":
            return {
                ...state,
                CusAddressStatus: false,
            };
        case "CHANGE_CUSTOMER_ADDRESS_STATE":
            return {
                ...state,
                CusAddressStatus: false,
            };

        case "GET_ADDRESS_DATA":
            return {
                ...state,
                cusAddressData: action.value,
            };

        case "GET_ADDRESS_DATA_MERGED":
            return {
                ...state,
                cusAddressDataMerged: action.value,
            };
        case "SET_ADDRESS_DATA_EMPTY":
            return {
                ...state,
                cusAddressData: null,
            };
        case "CHANGE_ADDRESS_UPDATE_STATE":
            return {
                ...state,
                CustomerAdressUpdateStatus: false,
            };
        case "ADDRESS_UPDATED":
            return {
                ...state,
                CustomerAdressUpdateStatus: true,
            };
        case "UPDATE_ADDRESS_OBJECT":
            return {
                ...state,
                CusStakeAddressOBJECT: action.value,
            };

        case "UPDATE_ADDRESS_UNSUCCESS":
            return {
                ...state,
                CustomerAdressUpdateStatus: false,
            };

        case "GET_CUSTOMER_SEARCHED_DATA":
            return {
                ...state,
                cusAddressSearchedData: action.value,
            };
        case "GET_CUS_CON_PER_DATA":
            return {
                ...state,
                CusConPerData: action.value,
            };

        case "GET_CUS_CON_PER_ONLY_DATA":
            return {
                ...state,
                CusConPerDataOnly: action.value,
            };

        case "ADD_CUSTOMER_CON_PERSON":
            return {
                ...state,
                CusConPerOBJECT: action.value,
                CusConPerStatus: true,
            };
        case "CHANGE_CUSTOMER_CON_PERSON_STATE":
            return {
                ...state,
                CusConPerStatus: false,
            };
        case "ADD_SALESREPRESENT_SUCCESS":
            return {
                ...state,
                addsalsesRepresentative: true,
            };
        case "ADD_SALESREPRESENT_FAILED":
            return {
                ...state,
                addsalsesRepresentative: false,
            };
        case "GET_SALES_REPRESENTATIVE_DATA":
            console.log(action.value);
            return {
                ...state,
                salerepData: action.value,
            };
        case "GET_SALES_REPRESENTATIVE_DATA_ONLY":
            return {
                ...state,
                salerepDataONLY: action.value,
            };

        case "UPDATE_SALES_REPRESENTATIVE_DATA_UNSUCCESS":
            return {
                ...state,
                updaterepresentative: false,
            };

        case "CHANGE_REPRESENTATIVE_DATA":
            return {
                ...state,
                addsalsesRepresentative: false,
            };

        case "GET_ASSIGNED_ITEMS":
            return {
                ...state,
                assignedItemsOriginal: action.value,
                assignedItems: action.value,
                assignedItemsLoded: true,
            };
        case "GET_ASSIGNED_ITEMS_ALL":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    assignedItemsAll: action.value.rows,
                    assignedItemsAllPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    assignedItemsAll: action.value,
                };
            }

        case "ADD_ASSIGNED_ITEMS":
            return {
                ...state,
                assignedItems: action.value,
                assignedItemsUpdated: true,
                assignedItemsLoded: true,
            };
        case "ASSIGN_ITEM_SUCCESS":
            return {
                ...state,
                ItemAssignedStatus: true,
                AddItemAssignedLoadingStatus: false,
            };
        case "ASSIGN_ITEM_OBJECT_SUCCESS":
            let itemObject = [...state.ItemObject];
            itemObject.push(action.value);
            return {
                ...state,
                ItemObject: itemObject,
            };
        case "ADD_ASSIGNITEM_LOADING":
            return {
                ...state,
                AddItemAssignedLoadingStatus: true,
            };
        case "CHANGE_ASSIGN_ITEM_STATE":
            return {
                ...state,
                ItemAssignedStatus: false,
            };
        case "ASSIGN_ITEM_FAIL":
            return {
                ...state,
                ItemAssignedStatus: false,
                AddItemAssignedLoadingStatus: false,
            };

        case "GET_ASSIGNED_ITEM_DATA":
            return {
                ...state,
                AssignedItem: action.value,
            };
        case "ASSIGNED_ITEM_UPDATED":
            return {
                ...state,
                AssignedItemUpdateStatus: true,
                AssignedItemUpdateLoadingStatus: false,
            };
        case "ASSIGNED_ITEM_OBJECT_UPDATED":
            let itemData = [...state.ItemObject];
            itemData[action.value[1]].itemId = action.value[0].itemId;
            itemData[action.value[1]].itemName = action.value[0].itemName;
            itemData[action.value[1]].dis = action.value[0].dis;
            itemData[action.value[1]].Price = action.value[0].Price;
            return {
                ...state,
                ItemObject: itemData,
            };
        case "DELETE_ITEM_OBJECT":
            let ItemObjectData = [...state.ItemObject];
            ItemObjectData.splice(action.value, 1);
            return {
                ...state,

                ItemObject: ItemObjectData,
            };
        case "UPDATE_ASSIGNEDITEM_LOADING":
            return {
                ...state,
                AssignedItemUpdateLoadingStatus: true,
            };
        case "UPDATE_ASSIGNED_ITEM_UNSUCCESS":
            return {
                ...state,
                AssignedItemUpdateStatus: false,
                AssignedItemUpdateLoadingStatus: false,
            };
        case "CHANGE_ASSIGNED_ITEM_UPDATE_STATE":
            return {
                ...state,
                AssignedItemUpdateStatus: false,
            };

        case "ASSIGNED_ITEM_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                AssignedItem: state.AssignedItem.filter((item) => item.id !== action.value),
            };
        case "RESET_CUSTOMER":
        case "CUSTOMER_ADDED_TO_DB":
            return {
                ...state,
                CustomerAddedStatus: true,
                CustomerOBJECT: null,
                CustomerOBJ: null,
                ItemObject: [],
                CusStakeAddressOBJECT: [],
                CusConPerOBJECT: null,
                DocumentData: null,
            };
        case "CHANGE_CUSTOMER_ADDED_TO_DB":
            return {
                ...state,
                CustomerAddedStatus: false,
            };

        case "ADD_CUS_REPRE_OBJECT":
            return {
                ...state,
                CustomerRePreOBJECT: action.value,
            };

        case "ADD_VIEW_LEVEL_DATA_SUCCESS":
            return {
                ...state,
                addViewlevelStatus: true,
            };
        case "ADD_VIEW_LEVEL_DATA_UNSUCCESS":
            return {
                ...state,
                addViewlevelStatus: false,
            };

        case "CHANGE_ADD_VIEW_LEVEL_DATA_SUCCESS_STATUS":
            return {
                ...state,
                addViewlevelStatus: false,
            };

        case "GET_VIEW_LEVEL_DATA":
            return {
                ...state,
                getViewLeveldata: action.value,
            };

        case "GET_BLOCKED_CUSTOMERS_DATA":
            return {
                ...state,
                getblockedCustomers: action.value,
            };
        case "GET_BLOCKED_CUSTOMERS_DETAILS":
            return {
                ...state,
                getblockedCustomersDetails: action.value,
            };

        case "ADD_UNBLOCK_CUSTOMER_SUCCESS":
            return {
                ...state,
                addUnblockORInactiveCustomerStatus: true,
            };

        case "ADD_UNBLOCK_CUSTOMER_SUCCESS_STATUS_CHANGED":
            return {
                ...state,
                addUnblockORInactiveCustomerStatus: false,
            };

        case "ADD_UNBLOCK_CUSTOMER_UNSUCCESS":
            return {
                ...state,
                addUnblockORInactiveCustomerStatus: false,
            };

        case "ADD_DOCUMENT_SUCCESS":
            return {
                ...state,
                AddDocumentStatus: true,
                AddDocumentLoadingStatus: false,
            };
        case "ADD_DOCUMENT_LOADING":
            return {
                ...state,
                AddDocumentLoadingStatus: true,
            };
        case "ADD_DOCUMENT_FAIL":
            return {
                ...state,
                AddDocumentStatus: false,
                AddDocumentLoadingStatus: false,
            };
        case "CHANGE_DOCUMENT_STATE":
            return {
                ...state,
                AddDocumentStatus: false,
            };

        case "GET_DOCUMENT_DATA":
            return {
                ...state,
                DocumentData: action.value,
            };

        case "RESET_DOCUMENT_DATA":
            return {
                ...state,
                DocumentData: null,
            };

        case "ADD_SUPPIERS_DATA_SUCCESS":
            console.log(action);
            return {
                ...state,
                addSuppliersData: true,
                LoadingaddSuppliersData: false,
            };

        case "ADD_SUPPIERS_DATA_LOADING":
            console.log(action);
            return {
                ...state,
                LoadingaddSuppliersData: true,
            };

        case "CHANGE_SUPPIERS_DATA_STATE":
            console.log(action);
            return {
                ...state,
                addSuppliersData: false,
            };

        case "ADD_SUPPIERS_DATA_FAIL":
            console.log(action);
            return {
                ...state,
                addSuppliersData: false,
                LoadingaddSuppliersData: false,
            };

        case "GET_INACTIVE_SUPPLIERS_DATA":
            return {
                ...state,
                getInactiveSuppliers: action.value,
            };

        case "GET_SUPPLIERS_BY_TOTAL_DUE_DATA":
            return {
                ...state,
                getSuppliersByTotalDue: action.value,
            };

        case "GET_INACTIVE_SUPPLIERS_DATA_UNSUCCESS":
            return {
                ...state,
                getInactiveSuppliers: [],
            };
        case "GET_SUPPLIER_ACTIVATION_DETAILS":
            return {
                ...state,
                getSupplierActivationDetails: action.value,
            };
        case "GET_SUPPLIER_ACTIVATION_DETAILS_UNSUCCESS":
            return {
                ...state,
                getSupplierActivationDetails: [],
            };

        case "GET_SUPPLIERS_DETAILS":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getSupplierDatapageSize: action.value.pages,
                    getSupplierLineData: action.value.rows,
                };
            } else {
                return {
                    ...state,
                    getSupplierLineData: action.value,
                };
            }

        case "GET_SUPPLIERS_DETAILS_UNSUCCESS":
            console.log(action);
            return {
                ...state,
                getSupplierLineData: null,
            };

        case "SUPPLIER_DATA_TO_UPDATE":
            console.log(action);
            return {
                ...state,
                supplierdataToUpdate: action.value,
            };

        case "SUPPLIER_DATA_TO_UPDATE_LOCATION":
            console.log(action);
            return {
                ...state,
                supplierdataToUpdateLocation: action.value,
            };

        case "TABLE_STATE_FOR_SUPPLIERS":
            console.log(action);
            return {
                ...state,
                tableStateForSuppliers: action.value,
            };

        case "CHECK_SUPPIERS_DATA_LOADING":
            console.log(action);
            return {
                ...state,
                checkSupplierdata: true,
            };

        case "CHECK_SUPPIERS_DATA":
            console.log(action.value);
            return {
                ...state,
                checkSupplierdata: false,
                checkingSupplierStatus: true,
                addSuplliersdata: action.value,
            };

        case "CHANGE_CHECK_SUPPIERS_DATA_STATUS":
            console.log(action);
            return {
                ...state,

                checkingSupplierStatus: false,
            };

        case "CHECK_SUPPIERS_DATA_FAIL":
            console.log(action);
            return {
                ...state,
                checkSupplierdata: false,
                checkingSupplierStatus: false,
            };

        case "":
            console.log(action);
            return {
                ...state,
                checkSupplierdata: false,
                checkingSupplierStatus: true,
            };

        case "ADD_SUPPLIER_DOCUMENT_LOADING":
            console.log(action);
            return {
                ...state,
                addSupplierDocLoading: true,
                addSupplierDocStatus: false,
            };

        case "ADD_SUPPLIER_DOCUMENT_SUCCESS":
            console.log(action.value);
            let supDocdata = [...state.supDocdata];

            supDocdata.push(action.value);

            return {
                ...state,
                addSupplierDocLoading: false,
                addSupplierDocStatus: true,
                supDocdata: supDocdata,
            };
        case "CHANGE_SUPPLIER_DOCUMENT_STATE":
            console.log(action);
            return {
                ...state,

                addSupplierDocStatus: false,
            };

        case "ADD_SUPPLIER_DOCUMENT_FAIL":
            console.log(action);
            return {
                ...state,
                addSupplierDocLoading: false,
                addSupplierDocStatus: false,
                temporarySupplierDocdata: null,
            };
        case "REMOVE_SUP_DOCS":
            return {
                ...state,
                supDocdata: action.value,
            };

        case "GET_SWAP_MNY_MODAL":
            return {
                ...state,
                SwapMnyModalState: !state.SwapMnyModalState,
                FromAccount: action.value !== undefined ? action.value : "",
            };

        case "ADD_SWAP_DATA_LOADING":
            return {
                ...state,
                SwapMnyLoadinglState: true,
            };
        case "ADD_SWAP_DATA_SUCCESS":
            if (action.value.currentPayOut) {
                return {
                    SwapMnyLoadinglState: false,
                    SwapMnyModalState: false,
                    PaymentVoucherModalState: false,
                    paymentVoucherDetails: action.value
                };
            } else {
                return {
                    ...state,
                    SwapMnyLoadinglState: false,
                    SwapMnyModalState: false,
                    PaymentVoucherModalState: false,
                };
            }

        case "ADD_SWAP_DATA_FAIL":
            return {
                ...state,
                SwapMnyLoadinglState: false,
            };

        case "GET_DOCUMEMTS_DATA_TO_UPDATE":
            return {
                ...state,
                getDocDataToUpdate: action.value,
            };
        case "GET_DOCUMEMTS_DATA_TO_UPDATE_UNSUCCESS":
            return {
                ...state,
                getDocDataToUpdate: null,
            };

        case "SAVE_SUPLIER_ID":
            console.log(action.value);
            return {
                ...state,
                saveSupplierId: action.value,
            };

        case "ADD_SUPPLIER_DOCUMENT_TODB_LOADING":
            return {
                ...state,
                supplierDocsLoading: true,
            };

        case "ADD_SUPPLIER_DOCUMENT_TODB_SUCCESS":
            return {
                ...state,
                supplierDocsLoading: false,
                addDocumentStatus: true,
            };
        case "CHANGE_ADD_SUPPLIER_DOCUMENT_TODB_SUCCESS":
            return {
                ...state,

                addDocumentStatus: false,
            };

        case "ADD_SUPPLIER_DOCUMENT_TODB_UNSUCCESS":
            return {
                ...state,
                supplierDocsLoading: false,
                addDocumentStatus: false,
            };

        case "DOCUMENTS_DELETED_STATUS":
            return {
                ...state,

                addDocumentStatus: false,
            };

        case "DOCS_DELETED_FAILED":
            return {
                ...state,
                supplierDocsLoading: false,
                addDocumentStatus: false,
            };

        case "SUPPLIERS_SURCHARGE_TYPE_TO_INSERT":
            let assignSurchargeType = [...state.supplierSurchargeTypeToInsert];
            assignSurchargeType.push(action.value);
            console.log("hghj");
            return {
                ...state,

                supplierSurchargeTypeToInsert: assignSurchargeType,
            };

        case "SUPPLIERS_RAWMATERIAL_TO_INSERT":
            console.log(action.value.rawMaterialName);
            let assignRawMaterial = [...state.supplierRawMaterialToInsert];
            assignRawMaterial.push(action.value);
            return {
                ...state,

                supplierRawMaterialToInsert: assignRawMaterial,
            };

        case "SUPPLIERS_DISTRIBUTION_ITEM_TO_INSERT":
            let assignDistributionItem = [...state.supplierDistributionItemToInsert];
            assignDistributionItem.push(action.value);
            return {
                ...state,

                supplierDistributionItemToInsert: assignDistributionItem,
            };   
            
        case "SUPPLIERS_PURCHASE_SERVICE_TO_INSERT":
            let assignPurchaseService = [...state.supplierPurchaseServiceToInsert];
            assignPurchaseService.push(action.value);
            return {
                ...state,

                supplierPurchaseServiceToInsert: assignPurchaseService,
            };  

        case "RAW_MATERIAL_DATA_TO_UPDATE":
            console.log(newstate.supplierRawMaterialToInsert);
            var hh = action.value;
            let updatedArray = newstate.supplierRawMaterialToInsert.map((el) => (el.rawMaterialName === action.value.rawMaterialName ? Object.assign({}, el, hh) : el));
            console.log(updatedArray);
            return {
                ...state,

                currentarray: newstate.supplierRawMaterialToInsert,
                supplierRawMaterialToInsert: updatedArray,
            };

        case "SUPPLIERS_PURCHASE_SERVICE_TO_UPDATE":
            hh = action.value;
            updatedArray = newstate.supplierPurchaseServiceToInsert.map((el) => (el.serviceName === action.value.serviceName ? Object.assign({}, el, hh) : el));
            console.log(updatedArray);
            return {
                ...state,
                currentarray: newstate.supplierPurchaseServiceToInsert,
                supplierPurchaseServiceToInsert: updatedArray,
            };

        case "REMOVE_ASSIGN_RAW_MATERIAL":
            return {
                ...state,
                supplierRawMaterialToInsert: action.value,
            };

        case "REMOVE_SUPPLIERS_PURCHASE_SERVICE":
            return {
                ...state,
                supplierPurchaseServiceToInsert: action.value,
            };

        case "GET_DOCS_BY_SUP_ID":
            return {
                ...state,
                getDocsByID: action.value,
            };

        case "GET_DOCS_BY_SUP_ID_FAIL":
            return {
                ...state,
                getDocsByID: null,
            };

        case "GET_ASSIGN_RAW_MATERIAL_DATA_OF_SUPPLIERS":
            return {
                ...state,
                getAssignRawMaterialdataofCustomers: action.value,
            };
        case "GET_ASSIGN_DISTRIBUTION_ITEM_DATA_OF_SUPPLIERS":
            return {
                ...state,
                getAssignDistributionItemdataofSuppliers: action.value,
            };
        case "GET_ASSIGNED_SURCHARGE_TYPE_DATA":
            return {
                ...state,
                getAssignSurchargeTypeDataOfSuppliers: action.value,
            };
        case "LOADING_ASSIGN_NEW_RAW_MATERIALS_IN_UPDATE":
            return {
                ...state,
                loadingAssinfrawmaterialTosuppliers: true,
            };

        case "ASSIGN_NEW_RAW_MATERIALS_IN_UPDATE":
            return {
                ...state,
                loadingAssinfrawmaterialTosuppliers: false,
                adiingrawmaterialtoSupplier: true,
            };

        case "CHANGE_ASSIGN_NEW_RAW_MATERIALS_IN_UPDATE":
            return {
                ...state,
                adiingrawmaterialtoSupplier: false,
            };

        case "ASSIGN_NEW_RAW_MATERIALS_IN_UPDATE_FAILED":
            return {
                ...state,
                loadingAssinfrawmaterialTosuppliers: false,
                adiingrawmaterialtoSupplier: false,
            };

        case "LOADING_ASSIGN_NEW_DISTRIBUTION_ITEMS_IN_UPDATE":
            return {
                ...state,
                loadingAssinDistrutionItemTosuppliers: true,
            };

        case "ASSIGN_NEW_DISTRIBUTION_ITEMS_IN_UPDATE":
            return {
                ...state,
                loadingAssinDistrutionItemTosuppliers: false,
                adiingDistributionItemtoSupplier: true,
            };

        case "CHANGE_ASSIGN_NEW_DISTRIBUTION_ITEMS_IN_UPDATE":
            return {
                ...state,
                adiingDistributionItemtoSupplier: false,
            };

        case "ASSIGN_NEW_DISTRIBUTION_ITEMS_IN_UPDATE_FAILED":
            return {
                ...state,
                loadingAssinDistrutionItemTosuppliers: false,
                adiingDistributionItemtoSupplier: false,
            };

        case "LOADING_ITEMS":
            return {
                ...state,
                loadingItems: true,
                UpdateSuccess: false,
            };

        case "UPDATE_ITEMS_SUCCESS":
            return {
                ...state,
                UpdateSuccess: true,
                loadingItems: false,
            };

        case "UPDATE_ITEM_UNSUCCESS":
            return {
                ...state,
                loadingItems: false,
                UpdateSuccess: false,
            };

        case "ADD_ITEMS_SUCCESS":
            return {
                ...state,
                AddSuccess: true,
                loadingItems: false,
            };

        case "ADD_ITEM_UNSUCCESS":
            return {
                ...state,
                loadingItems: false,
                AddSuccess: false,
            };

        //////
        case "LOADING_SUPPLIERS_DISTRIBUTION_ITEMS_TO_UPDATE":
            return {
                ...state,
                loadingUpdateAssinedDisItemsTosuppliers: true,
            };

        case "UPDATE_ASSIGNED_DISTRIBUTION_ITEMS_SUCCESS":
            return {
                ...state,
                loadingUpdateAssinedDisItemsTosuppliers: false,
                UpdateDistributionItemstoSupplier: true,
            };

        case "CHANGE_UPDATE_ASSIGNED_DISTRIBUTION_ITEM_STATUS":
            return {
                ...state,
                UpdateDistributionItemstoSupplier: false,
            };

        case "UPDATE_ASSIGNED_DISTRIBUTION_ITEM_UNSUCCESS":
            return {
                ...state,
                loadingUpdateAssinedDisItemsTosuppliers: false,
                UpdateDistributionItemstoSupplier: false,
            };

        /////

        case "LOADING_SUPPLIERS_RAW_MATERIAL_TO_UPDATE":
            return {
                ...state,
                loadingUpdateRawMaterialTosuppliers: true,
            };

        case "UPDATE_RAW_MATERIAL_ASSIGN_SUCCESS":
            return {
                ...state,
                loadingUpdateRawMaterialTosuppliers: false,
                UpdateRawMaterialtoSupplier: true,
            };

        case "CHANGE_UPDATE_RAW_MATERIAL_ASSIGN_STATUS":
            return {
                ...state,
                UpdateRawMaterialtoSupplier: false,
            };

        case "UPDATE_RAW_MATERIAL_ASSIGN_UNSUCCESS":
            return {
                ...state,
                loadingUpdateRawMaterialTosuppliers: false,
                UpdateRawMaterialtoSupplier: false,
            };

        case "DELETED_ASSIGN_RAW_MATERIAL":
            return {
                ...state,
                deleteAssignRawmaterial: true,
            };
        case "DELETED_ASSIGN_RAW_MATERIAL_FAILED":
            return {
                ...state,
                deleteAssignRawmaterial: false,
            };

        case "ADD_SUPPLIER_CON_PERSON":
            console.log(action.value);
            return {
                ...state,
                SupConPerOBJECT: action.value,
            };
        case "GET_SUPPLIER_CONTACT_PERSON_DATA":
            console.log(action.value);
            return {
                ...state,
                getSupplierContactPersonData: action.value,
            };

        case "TEMPORARY_SUPPLIER_CONTACT_PERSON":
            console.log(action);
            return {
                ...state,

                tempararySupplierContactPerson: action.value,
            };

        case "INSERT_ALL_SUPPLIER_DATA_SUCCESSFULLY":
            console.log(action);
            return {
                ...state,

                insertAllSupplierData: true,
            };

        case "LOADING_TO_INSERT_ALL_SUPPLIER_DATA":
            return {
                ...state,
                loadingAllSupplierDataToInsert: true,
            };

        case "CHANGE_INSERT_ALL_SUPPLIER_DATA_SUCCESSFULLY":
            return {
                ...state,
                loadingAllSupplierDataToInsert: false,
                insertAllSupplierData: false,
            };

        case "INSERT_ALL_SUPPLIER_DATA_UNSUCCESSFULLY":
            return {
                ...state,
                loadingAllSupplierDataToInsert: false,
                insertAllSupplierData: false,
            };
        case "CLEAR_SUPPLIER_STORED_DATA":
            console.log(action);
            return {
                ...state,

                getSupplierContactPersonData: null,
                supDocdata: [],
                supplierRawMaterialToInsert: [],
                supplierSurchargeTypeToInsert: [],
                supplierDistributionItemToInsert: [],
                supplierPurchaseServiceToInsert: [],
                addSuplliersdata: [],
            };
        case "CLEAR_SUPPLIER_DOC_DATA":
            return {
                ...state,

                supDocdata: [],
            };

        case "GET_SUP_RAW_MATERIAL_DOCS":
            console.log(action.value);
            return {
                ...state,
                getRawmaterialDOCsdata: action.value,
            };

        case "GET_SUP_DISTRIBUTION_ITEM_DOCS":
            console.log(action.value);
            return {
                ...state,
                getSupAssignedDisItemDOCsdata: action.value,
            };
        case "GET_WEB_USER_DATA":
            if (action.value.rows !== undefined) {
                console.log(JSON.stringify(action.value.rows));
                return {
                    ...state,
                    getwebuserdataPages: action.value.pages,
                    getwebuserdata: action.value.rows,
                };
            } else {
                return {
                    ...state,
                    getwebuserdata: action.value,
                };
            }

        case "WEB_USER_DATA_TO_UPDATE":
            console.log(action.value);
            return {
                ...state,
                webUserdataToUpdate: action.value,
            };

        case "GET_WEB_USER_ADDRESS_DATA":
            console.log(action.value);
            return {
                ...state,
                getWebUserAddressdata: action.value,
            };
        case "GET_WEB_USER_ADDRESS_DATA_UNSUCCESS":
            return {
                ...state,
                getWebUserAddressdata: null,
            };
        case "LOADING_WEB_USER_ADDRESS_DATA":
            return {
                ...state,
                loadingWebUserAddressdata: true,
            };
        case "ADD_ADDRESS_TO_WEB_USER":
            return {
                ...state,
                loadingWebUserAddressdata: false,
                addAddressToWebUser: true,
            };
        case "ADD_ADDRESS_TO_WEB_USER_CHANGE":
            return {
                ...state,

                addAddressToWebUser: false,
            };

        case "ADD_ADDRESS_TO_WEB_USER_UNSUCCESS":
            return {
                ...state,
                loadingWebUserAddressdata: false,
                addAddressToWebUser: false,
            };

        case "LOADING_UPDATE_WEB_USER_ADDRESS_UNSUCCESS":
            return {
                ...state,
                loadingWebUserupdatedAddressdata: true,
            };
        case "WEB_USER_ADDRESS_UPDATED":
            return {
                ...state,
                loadingWebUserupdatedAddressdata: false,
                webAdreessUpdateStatus: true,
            };
        case "CHANGE_WEB_USER_ADDRESS_UPDATE_STATE":
            return {
                ...state,

                webAdreessUpdateStatus: false,
            };

        case "UPDATE_WEB_USER_ADDRESS_UNSUCCESS":
            return {
                ...state,
                loadingWebUserupdatedAddressdata: false,
                webAdreessUpdateStatus: false,
            };

        case "GET_SUPPLIER_TAX_OPTION_DATA":
            return {
                ...state,
                SupplierTaxData: action.value,
            };

        case "GET_CUSTOMER_ITEM_DATA":
            return {
                ...state,
                customerItemData: action.value,
            };
            
        case "UPLOAD_OUTLET_STOCK_UNSUCCESS":
            return {
                ...state,
                outletUploadStatus: false,
            };

        case "UPLOAD_OUTLET_STOCK_SUCCESS":
            return {
                ...state,
                outletUploadStatus: true,
            };
        
        case "PAYMENT_MODAL_STATE":
            return {
                ...state,
                PaymentVoucherModalState: true,
            };

        case "CLOSE_PAYMENT_MODAL_STATE":
            return {
                ...state,
                PaymentVoucherModalState: action.value,
            };
            
        case "GET_TRANSFER_SWAP_MNY_MODAL":
            return {
                ...state,
                SwapMnyTransferModalState: !state.SwapMnyTransferModalState,
                TransferAccount: action.value !== undefined ? action.value : "",
            };

        default:
            break;
    }

    return newstate;
};
export default reducer;
