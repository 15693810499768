const initialState = {
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {

        case "GET_PURCHASE_SERVICE_SUCCESS":
            return {
                ...state,
                getPurchasedService: action.value,
            };

        case "LOADING_PURCHASE_SERVICE":
            return {
                ...state,
                addPurchaseServiceData: true,
                loadingPurchaseServiceData: true,
            };
        
        case "ADD_PURCHASE_SERVICE_DATA_SUCCESS":
            return {
                ...state,
                addPurchaseServiceData: true,
                loadingPurchaseServiceData: false,
            };

        case "CHANGE_ADD_PURCHASE_SERVICE_DATA_SUCCESS":
            return {
                ...state,
                addPurchaseServiceData: false,
            };

        case "ADD_PURCHASE_SERVICE_DATA_UNSUCCESS":
            return {
                ...state,
                addPurchaseServiceData: false,
                loadingPurchaseServiceData: false,
            };

        case "LOADING_CHANGE_UPDATE_PURCHASE_SERVICE":
            return {
                ...state,
                loadingUpdatedPurchaseService: true,
            };

        case "PURCHASE_SERVICE_DATA_UPDATED":
            return {
                ...state,
                updatePurchaseServiceData: true,
                loadingUpdatedPurchaseService: false,
            };

        case "CHANGE_PURCHASE_SERVICE_DATA_UPDATED_STATUS":
            return {
                ...state,
                updatePurchaseServiceData: false,
            };

        case "PURCHASE_SERVICE_DATA_UPDATED_UNSUCCESS":
            return {
                ...state,
                updatePurchaseServiceData: false,
                loadingUpdatedPurchaseService: false,
            };

        case "GET_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                getPurchaseServiceBySupplier: action.value,
            };

        case "LOADING_ADD_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                loadingAddSupplierPurchaseService: true,
            };

        case "ADD_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                loadingAddSupplierPurchaseService: false,
                addSupplierPurchaseService: true,
            };

        case "CHANGE_ADD_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                addSupplierPurchaseService: false,
            };

        case "ADD_PURCHASE_SERVICE_FOR_SUPPLIERS_FAILED":
            return {
                ...state,
                loadingAddSupplierPurchaseService: false,
                addSupplierPurchaseService: false,
            };

        
        case "LOADING_UPDATE_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                loadingUpdateSupplierPurchaseService: true,
            };

        case "UPDATE_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                loadingUpdateSupplierPurchaseService: false,
                updateSupplierPurchaseService: true,
            };

        case "CHANGE_UPDATE_PURCHASE_SERVICE_FOR_SUPPLIERS":
            return {
                ...state,
                updateSupplierPurchaseService: false,
            };

        case "UPDATE_PURCHASE_SERVICE_FOR_SUPPLIERS_UNSUCCESS":
            return {
                ...state,
                loadingUpdateSupplierPurchaseService: false,
                updateSupplierPurchaseService: false,
            };

        default:
            break;
    }
    return newstate;
};
export default reducer;