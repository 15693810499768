/**
 * @file This file defines for Redirecting to Login Page if Token is Not availble
 * @author Nipun Upekshana
 */
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { loadingPage } from "./containers/DefaultLayout/LoadingComponent";
import "./App.scss";
import { connect } from "react-redux";
import { loadUser } from "./actions/authActions";
import PrimeReact from 'primereact/utils';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Containers
const DefaultLayout = React.lazy(() =>
    import("./containers/DefaultLayout/DefaultLayout")
);

// Pages
const Login = React.lazy(() => import("./views/Login/Login"));

PrimeReact.ripple = true;

class App extends Component {
    componentDidMount() {
        this.props.LOADUSER();
    }

    render() {
        return (
            <Router>
                <React.Suspense fallback={loadingPage()}>
                    {!this.props.isAuthenicated ? (
                        <Fragment>
                            <Route
                                path="*"
                                name="Login Page"
                                render={(props) => <Login {...props} />}
                            />

                        </Fragment>
                    ) : (
                        <Fragment>
                            <Route
                                name="Home"
                                path="/"
                                render={(props) => <DefaultLayout {...props} />}
                            />
                        </Fragment>
                    )}
                </React.Suspense>
            </Router>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenicated: state.auth.isAuthenicated,
    isLoading: state.auth.isLoading,
    error: state.error,
    token: state.auth.token,
});

const mapDispachToProps = (dispach) => {
    return {
        LOADUSER: () => dispach(loadUser()),
    };
};

export default connect(mapStateToProps, mapDispachToProps)(App);
