const initialState = {
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {

        case "GET_ACCOUNT_PARAMETER_DATA":
            return {
                ...state,
                accountParameterData: action.value,
            };

        case "ADD_ACCOUNT_PARAMETER_LOADING":
            return {
                ...state,
                accountParameterLoadingStatus: true,
            };

        case "ADD_ACCOUNT_PARAMETER_SUCCESS":
            return {
                ...state,
                accountParameterLoadingStatus: false,
                accountParameterAddStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_STATE":
            return {
                ...state,
                accountParameterAddStatus: false,
            };

        case "ADD_ACCOUNT_PARAMETER_FAIL":
            return {
                ...state,
                accountParameterLoadingStatus: false,
                accountParameterAddStatus: false,
            };

        case "UPDATE_ACCOUNT_PARAMETER_LOADING":
            return {
                ...state,
                loadingUpdateAccountParameter: true,
            };

        case "ACCOUNT_PARAMETER_UPDATED":
            return {
                ...state,
                loadingUpdateAccountParameter: false,
                accountParameterUpdateStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_UPDATE_STATE":
            return {
                ...state,
                accountParameterUpdateStatus: false,
            };

        case "UPDATE_ACCOUNT_PARAMETER_UNSUCCESS":
            return {
                ...state,
                loadingUpdateAccountParameter: false,
                accountParameterUpdateStatus: false,
            };

        case "ADD_ACCOUNT_PARAMETER_COLLECTION_LOADING":
            return {
                ...state,
                accountParameterCollectionLoadingStatus: true,
            };

        case "ADD_ACCOUNT_PARAMETER_COLLECTION_SUCCESS":
            return {
                ...state,
                accountParameterCollectionLoadingStatus: false,
                accountParameterCollectionAddStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_COLLECTION_STATE":
            return {
                ...state,
                accountParameterCollectionAddStatus: false,
            };

        case "ADD_ACCOUNT_PARAMETER_COLLECTION_FAIL":
            return {
                ...state,
                accountParameterCollectionLoadingStatus: false,
                accountParameterCollectionAddStatus: false,
            };

        case "GET_ACCOUNT_PARAMETER_COLLECTION_DATA":
            return {
                ...state,
                accountParameterCollectionData: action.value,
            };

        case "UPDATE_ACCOUNT_PARAMETER_COLLECTION_LOADING":
            return {
                ...state,
                loadingUpdateAccountParameterCollection: true,
            };

        case "ACCOUNT_PARAMETER_COLLECTION_UPDATED":
            return {
                ...state,
                loadingUpdateAccountParameterCollection: false,
                accountParameterCollectionUpdateStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_COLLECTION_UPDATE_STATE":
            return {
                ...state,
                accountParameterCollectionUpdateStatus: false,
            };

        case "UPDATE_ACCOUNT_PARAMETER_COLLECTION_UNSUCCESS":
            return {
                ...state,
                loadingUpdateAccountParameterCollection: false,
                accountParameterCollectionUpdateStatus: false,
            };

        case "GET_ACCOUNT_ACCOUNT_PARAMETER_DATA":
            return {
                ...state,
                accountAccountParameterData: action.value,
            };

        case "GET_ACCOUNT_PARAMETER_BY_NOT_ACCOUNT":
            return {
                ...state,
                accountParameterData: action.value,
            };

        case "ADD_ACCOUNT_PARAMETER_ACCOUNT_LOADING":
            return {
                ...state,
                accountParameterAccountLoadingStatus: true,
            };

        case "ADD_ACCOUNT_PARAMETER_ACCOUNT_SUCCESS":
            return {
                ...state,
                accountParameterAccountLoadingStatus: false,
                accountParameterAccountAddStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_ACCOUNT_STATE":
            return {
                ...state,
                accountParameterAccountAddStatus: false,
            };

        case "ADD_ACCOUNT_PARAMETER_ACCOUNT_FAIL":
            return {
                ...state,
                accountParameterAccountLoadingStatus: false,
                accountParameterAccountAddStatus: false,
            };

        case "UPDATE_ACCOUNT_PARAMETER_ACCOUNT_LOADING":
            return {
                ...state,
                accountParameterAccountLoadingStatus: true,
            };

        case "ACCOUNT_PARAMETER_ACCOUNT_UPDATED":
            return {
                ...state,
                accountParameterAccountLoadingStatus: false,
                accountParameterAccountAddStatus: true,
            };

        case "CHANGE_ACCOUNT_PARAMETER_ACCOUNT_UPDATE_STATE":
            return {
                ...state,
                accountParameterAccountAddStatus: false,
            };

        case "UPDATE_ACCOUNT_PARAMETER_ACCOUNT_UNSUCCESS":
            return {
                ...state,
                accountParameterAccountLoadingStatus: false,
                accountParameterAccountAddStatus: false,
            };

        case "CLOSE_ACCOUNT_PARAMETER_MODAL":
            return {
                ...state,
                handleModal: action.value,
            };

        case "GET_ACCOUNT_PARAMETER_VALUE_DATA":
            return {
                ...state,
                accountParameterValueData: action.value,
            };

        case "GET_ALL_ACCOUNT_PARAMETER_DETAILS":
            return {
                ...state,
                allaccountParameterDetailData: action.value,
            };      
            
        default:
            break;
    }

    return newstate;
};

export default reducer;