import { combineReducers } from "redux";
import itemReducer from "./itemReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import pageReducer from "./pageReducer";
import tableReducer from "./tableReducer";
import AuthApplicationSettingReducer from "./AuthApplicationSettingReducer";
import StakeholderReducer from "./StakeholderReducer";
import InvoiceHandleReducer from "./InvoiceHandleReducer";
import warehouseReducer from "./WarehousemanageReducer";
import ProductionReducer from "./ProductionReducer";
import PurchaseOrderReducer from "./Purchasing/purchaseOrderReducer";
import GRNReducer from "./Purchasing/goodsReceiveReducer";
import SupplierReducer from "./Supplier/supplierReducer";
import purchasingItemsReducer from "./Purchasing/purchasingItemsReducer";
import InvoiceReport from "./Report/invoiceReport";
import payOutReducer from "./Purchasing/payOutReducer";
import purchasedServiceReducer from "./material/purchasedServiceReducer";
import accountParameterReducer from "./invoice/accountParameterReducer";
import commissionReducer from "./commission/commissionReducer";

export default combineReducers({
    item: itemReducer,
    auth: authReducer,
    error: errorReducer,
    page: pageReducer,
    table: tableReducer,
    AppSetting: AuthApplicationSettingReducer,
    StakeHolderSetting: StakeholderReducer,
    invoice: InvoiceHandleReducer,
    warehouse: warehouseReducer,
    production: ProductionReducer,
    purchaseOrder: PurchaseOrderReducer,
    grn: GRNReducer,
    supplier: SupplierReducer,
    purchasingItem: purchasingItemsReducer,
    invoiceReport: InvoiceReport,
    payOut: payOutReducer,
    purchasedService: purchasedServiceReducer,
    accountParameter: accountParameterReducer,
    commissionReducer : commissionReducer
});
