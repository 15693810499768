const initialState = {
    addWarehouseStatus: false,
    getwarehousedata: null,
    updateWarehouseStatus: false,
    addwarehouseRoomstatus: false,
    getwarehouseRoomData: null,
    warehouseRoomDataPageSize: null,
    updatewarehouseRoomStatus: false,
    deletewarehouseRoomStatus: false,
    loadingWarehouse: false,
    updatewarehouseloading: false,
    addWarehoseLoadingStatus: false,
    loadingUpdatedStatus: false,
    addWarehouseLocationSucess: false,
    addWarehouseLocationSucessStatus: false,
    warehouseLocationData: null,
    deletewarehouseSuccess: false,
    updateWarehouseSuccess: false,
    loadingupdateWarehouseStatus: false,
    addWarehouseBinLoading: false,
    addwarehouseBin: false,
    warehousebinData: null,
    warehousebinDataPageSize: 0,
    loadingwarehouseBinData: false,
    updateWarehousebinSuccess: false,
    deleteWarehouseBin: false,
    getBarcodedataByWBinID: null,
    addAssignedBarcodes: [],
    assignedBarcodeUpdated: false,
    assignedBarcodeLoded: false,
    getbinBarcodeItemdata: null,
    UpdateBarcodeStatus: false,
    assignedBarcodeLoded2: false,
    getBarcodedataByWBinID2: null,
    getBarcodedataByWBinIDPageSize: 0,
    getBarcodedataByWBinID2PageSize: 0,
    loadingStoreRM: false,
    addStoreRM: false,
    getStoredRMdata: null,
    getStoredRMdataPageSize: 0,
    loadingUpdatinngStoringRM: false,
    updaingStoringRm: false,
    loadingconfirmWarehouseBinData: false,
    confirmWarehouseBinData: false,
    getDeafultWarehouseBinData: null,
    WarehouseItemData: [],
    WarehouseItemDataGroupedByWarehouse: [],
    WarehouseSelectedItemData: [],
    SelectedWarehouseItemData: [],
    SelectedWarehouseBinItemData: [],
    SelectedItemDataForAllWarehouseBin: [],
    loadingItemWarehouseBinSwap: false,
    ItemWarehouseBinSwapped: false,
    ProduceItemData: [],
    StockReportData: [],
    loadingAccountTransfer: false,
    addAccountTransfer: false,
    RawMaterialReportData: [],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "ADD_WAREHOUSE_SUCCESS":
            return {
                ...state,
                addWarehouseStatus: true,
                loadingWarehouse: false,
            };

        case "ADD_WAREHOUSE_LOADING":
            return {
                ...state,
                loadingWarehouse: true,
            };
        case "ADD_WAREHOUSE_UNSUCCESS":
            return {
                ...state,
                addWarehouseStatus: false,
                loadingWarehouse: false,
            };
        case "CHANGE_UPDATE_WAREHOUSE_DATA_SUCCESS":
            return {
                ...state,
                updateWarehouseStatus: false,
            };
        case "CHANGE_WAREHOUSE_SUCCESS_STATUS":
            return {
                ...state,
                addWarehouseStatus: false,
            };

        case "GET_WAREHOUSE_DATA":
            if (action.value.rows !== undefined) {
                console.log(action.value.rows);
                return {
                    ...state,
                    getwarehousedata: action.value.rows,
                    warehouseDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getwarehousedata: action.value,
                };
            }
        case "UPDATE_WAREHOUSE_DATA_SUCCESS":
            return {
                ...state,
                updateWarehouseStatus: true,
                updatewarehouseloading: false,
            };

        case "UPDATE_WAREHOUSE_DATA_UNSUCCESS":
            return {
                ...state,
                updateWarehouseStatus: false,
                updatewarehouseloading: false,
            };

        case "ADD_WAREHOUSE_ROOM_SUCCESS":
            return {
                ...state,
                addwarehouseRoomstatus: true,
                addWarehoseLoadingStatus: false,
            };

        case "CHANGE_WAREHOUSE_ROOM_SUCCESS_STATUS":
            return {
                ...state,
                addwarehouseRoomstatus: false,
            };

        case "ADD_WAREHOUSE_ROOM_UNSUCCESS":
            return {
                ...state,
                addwarehouseRoomstatus: false,
                addWarehoseLoadingStatus: false,
            };

        case "GET_WAREHOUSE_ROOM_DATA":
            if (action.value.rows !== undefined) {
                console.log(action.value.pages);
                return {
                    ...state,
                    getwarehouseRoomData: action.value.rows,
                    warehouseRoomDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getwarehouseRoomData: action.value,
                };
            }

        case "UPDATE_WAREHOUSE_ROOM_DATA_SUCCESS":
            return {
                ...state,
                updatewarehouseRoomStatus: true,
                loadingUpdatedStatus: false,
            };

        case "CHANGE_UPDATE_WAREHOUSE_ROOM_DATA_SUCCESS":
            return {
                ...state,
                updatewarehouseRoomStatus: false,
                loadingUpdatedStatus: false,
            };

        case "UPDATE_WAREHOUSE_ROOM_DATA_UNSUCCES":
            return {
                ...state,
                updatewarehouseRoomStatus: false,
                loadingUpdatedStatus: false,
            };
        case "UPDATE_WAREHOUSE_LOADING":
            return {
                ...state,
                updatewarehouseloading: true,
            };

        case "WAREHOUSEROOM_DELETED":
            return {
                ...state,
                deletewarehouseRoomStatus: true,
            };
        case "WAREHOUSEROOM_DELETED_UNSUCESS":
            return {
                ...state,
                deletewarehouseRoomStatus: false,
            };
        case "ADD_WAREHOUSEROOM_LOADING":
            return {
                ...state,
                addWarehoseLoadingStatus: true,
            };
        case "LOADING_UPDATE_WAREHOUSE_ROOM_STATUS":
            return {
                ...state,
                loadingUpdatedStatus: true,
            };

        case "ADD_WAREHOUSE_LOCATION_SUCCESS":
            return {
                ...state,
                addWarehouseLocationSucess: true,

                LoadingaddWarehouseLocationSucess: false,
            };

        case "ADD_WAREHOUSE_LOCATION_UNSUCCESS":
            return {
                ...state,
                addWarehouseLocationSucess: false,

                LoadingaddWarehouseLocationSucess: false,
            };

        case "CHANGE_WAREHOUSE_LOCATION_SUCCESS_STATUS":
            return {
                ...state,
                addWarehouseLocationSucess: false,
                LoadingaddWarehouseLocationSucess: false,
            };

        case "ADD_WAREHOUSELOCATION_LOADING":
            return {
                ...state,
                LoadingaddWarehouseLocationSucess: true,
            };

        case "GET_WAREHOUSE_LOCATION_DATA":
            if (action.value.rows !== undefined) {
                console.log(action.value.pages);
                return {
                    ...state,
                    warehouseLocationData: action.value.rows,
                    warehouseLocationDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    warehouseLocationData: action.value,
                };
            }

        case "WAREHOUSE_LOCATION_DELETED":
            return {
                ...state,
                deletewarehouseSuccess: true,
            };

        case "WAREHOUSE_LOCATION_DELETED_UNSUCESS":
            return {
                ...state,
                deletewarehouseSuccess: false,
            };

        case "UPDATE_WAREHOUSE_LOCATION_DATA_SUCCESS":
            return {
                ...state,
                updateWarehouseSuccess: true,
                loadingupdateWarehouseStatus: false,
            };
        case "CHANGE_UPDATE_WAREHOUSE_LOCATION_DATA_SUCCESS":
            return {
                ...state,
                updateWarehouseSuccess: false,
            };

        case "UPDATE_WAREHOUSE_LOCATION_DATA_UNSUCCES":
            return {
                ...state,
                updateWarehouseSuccess: false,
                loadingupdateWarehouseStatus: false,
            };

        case "LOADING_UPDATE_WAREHOUSE_LOCATION_STATUS":
            return {
                ...state,
                loadingupdateWarehouseStatus: true,
            };

        case "ADD_WAREHOUSE_BIN_LOADING":
            return {
                ...state,
                addWarehouseBinLoading: true,
            };

        case "ADD_WAREHOUSE_BIN_SUCCESS":
            return {
                ...state,
                addWarehouseBinLoading: false,
                addwarehouseBin: true,
            };

        case "ADD_WAREHOUSE_BIN_UNSUCCESS":
            return {
                ...state,
                addWarehouseBinLoading: false,
                addwarehouseBin: false,
            };

        case "CHANGE_WAREHOUSE_BIN_SUCCESS_STATUS":
            return {
                ...state,

                addwarehouseBin: false,
            };
        case "GET_WAREHOUSE_BIN_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    warehousebinData: action.value.rows,
                    warehousebinDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    warehousebinData: action.value,
                };
            }

        case "GET_WAREHOUSE_BIN_DATA_UNSUCEESS":
            return {
                ...state,

                warehouseBinData: null,
            };
        case "GET_BIN_DEFAULT_BARCODES_DATA":
            console.log(action.value);
            return {
                ...state,

                getDeafultWarehouseBinData: action.value,
            };
        case "GET_BIN_DEFAULT_BARCODES_DATA_FAILED":
            return {
                ...state,

                warehouseBigetDeafultWarehouseBinDatanData: null,
            };
        case "LOADING_UPDATE_WAREHOUSE_BIN_STATUS":
            return {
                ...state,

                loadingwarehouseBinData: true,
            };
        case "UPDATE_WAREHOUSE_BIN_DATA_SUCCESS":
            return {
                ...state,

                loadingwarehouseBinData: false,
                updateWarehousebinSuccess: true,
            };
        case "CHANGE_UPDATE_WAREHOUSE_BIN_SUCCESS_STATUS":
            return {
                ...state,
                updateWarehousebinSuccess: false,
            };
        case "UPDATE_WAREHOUSE_BIN_DATA_UNSUCCES":
            return {
                ...state,
                loadingwarehouseBinData: false,
                updateWarehousebinSuccess: false,
            };

        case "WAREHOUSE_BIN_DELETED":
            return {
                ...state,
                deleteWarehouseBin: true,
            };

        case "WAREHOUSE_BIN_DELETED_UNSUCESS":
            return {
                ...state,
                deleteWarehouseBin: false,
            };

        // case "GET_BARCODE_ITEM_DATA":
        //   console.log(action.value)
        //   return {
        //     ...state,
        //     getBarcodedataByWBinID: action.value
        //   };
        case "GET_BARCODE_ITEM_DATA":
            if (action.value.rows !== undefined) {
                console.log(action.value.rows);
                return {
                    ...state,
                    getBarcodedataByWBinID: action.value.rows,
                    getBarcodedataByWBinIDPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getBarcodedataByWBinID: action.value,
                };
            }
        case "GET_BARCODE_ITEM_DATA_UNSUCEESS":
            return {
                ...state,
                getBarcodedataByWBinID: null,
            };

        // case "GET_BARCODE_ITEM_DATA_2":
        // console.log(action.value)
        // return {
        //   ...state,
        //   assignedBarcodeLoded2 : true,
        //   getBarcodedataByWBinID2: action.value
        // };
        case "GET_BARCODE_ITEM_DATA_2":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    assignedBarcodeLoded2: true,
                    getBarcodedataByWBinID2: action.value.rows,
                    getBarcodedataByWBinID2PageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getBarcodedataByWBinID2: action.value,
                };
            }
        case "GET_BARCODE_ITEM_DATA_2_UNSUCEESS":
            return {
                ...state,
                assignedBarcodeLoded2: false,
                getBarcodedataByWBinID2: null,
            };
        case "ADD_ASSIGNED_BARCODES":
            console.log(action.value);
            return {
                ...state,
                addAssignedBarcodes: action.value,
                assignedBarcodeUpdated: true,
                assignedBarcodeLoded: true,
            };

        case "CONVERT_RAW_MATERIAL":
            return {
                ...state,
                convertRawMaterials: action.value,
                itemqtymodel: true,
            };

        case "GET_BIN_BARCODE_ITEM_DATA":
            console.log(action.value);
            return {
                ...state,
                getbinBarcodeItemdata: action.value,
            };

        case "LOADING_ASSIGNED_BARCODE_UPDATED_SUCCFULLY":
            console.log(action.value);
            return {
                ...state,
                loadingAssignBarcodeData: true,
            };
        case "CHANGE_BACODE_SUCCESS_STATUS":
            console.log(action.value);
            return {
                ...state,
                UpdateBarcodeStatus: false,
            };
        case "ASSIGNED_BARCODE_UPDATED_SUCCFULLY":
            console.log(action.value);
            return {
                ...state,
                loadingAssignBarcodeData: false,
                UpdateBarcodeStatus: true,
                // addAssignedBarcodes: []
            };

        case "GET_BIN_BARCODE_ITEM_DATA_UNSUCEESS":
            return {
                ...state,
                getbinBarcodeItemdata: null,
            };

        case "ASSIGNED_BARCODE_UPDATED_UNSUCEESS":
            return {
                ...state,
                UpdateBarcodeStatus: false,
            };

        case "CHANGE_BACODE_STATUS":
            return {
                ...state,
                UpdateBarcodeStatus: false,
            };

        case "ADD_STORE_RM_LOADING":
            console.log(action.value);
            return {
                ...state,
                loadingStoreRM: true,
            };
        case "ADD_STORE_RM":
            return {
                ...state,
                loadingStoreRM: false,
                addStoreRM: true,
            };
        case "CHANGE_ADD_STORE_RM_STATUS":
            return {
                ...state,
                getbinBarcodeItemdata: null,
                addStoreRM: false,
            };

        case "ADD_STORE_RM_FAILED":
            return {
                ...state,
                loadingStoreRM: false,
                addStoreRM: false,
            };

        case "GET_STORED_RM_DATA":
            return {
                ...state,
                getStoredRMdata: action.value.storeRMData,
                getStoredRMUsagedata: action.value.storeRMUsageData,
                getMaterialListDistribution: action.value.materialListDistribution,
            };
            
        case "GET_STORED_RM_DATA_UNSUCEESS":
            return {
                ...state,
                getStoredRMdata: null,
            };

        case "LOADING_UPDATE_STORE_RM_STATUS":
            return {
                ...state,
                loadingUpdatinngStoringRM: true,
            };

        case "UPDATE_UPDATE_STORE_RM_SUCCESS":
            console.log(action.value);
            return {
                ...state,
                loadingUpdatinngStoringRM: false,
                updaingStoringRm: true,
            };
        case "CHANGE_UPDATE_STORE_RM__SUCCESS_STATUS":
            return {
                ...state,
                updaingStoringRm: false,
            };
        case "UPDATE_STORE_RM__DATA_UNSUCCES":
            return {
                ...state,
                loadingUpdatinngStoringRM: false,
                updaingStoringRm: false,
            };

        case "LOADING_CONFIRM_WAREHOUSE_BIN_DATA_SUCCESS":
            return {
                ...state,
                loadingconfirmWarehouseBinData: true,
            };

        case "CONFIRM_WAREHOUSE_BIN_DATA_SUCCESS":
            return {
                ...state,
                loadingconfirmWarehouseBinData: false,
                confirmWarehouseBinData: true,
            };

        case "CONFIRM_WAREHOUSE_BIN_DATA_SUCCESS_CHANGED":
            console.log(action.value);
            return {
                ...state,
                confirmWarehouseBinData: false,
            };
        case "CONFIRM_WAREHOUSE_BIN_DATA_UNSUCCESS":
            return {
                ...state,
                loadingconfirmWarehouseBinData: false,
                confirmWarehouseBinData: false,
            };
        case "LOADING_CONFIRM_UPDATE_WAREHOUSE_BIN_DATA_SUCCESS":
            return {
                ...state,
                loadingconfirmUpdateWarehouseBinData: true,
            };

        case "CONFIRM_UPDATE_WAREHOUSE_BIN_DATA_SUCCESS":
            return {
                ...state,
                loadingconfirmUpdateWarehouseBinData: false,
                confirmUpdateWarehouseBinData: true,
            };

        case "CONFIRM_UPDATE_WAREHOUSE_BIN_DATA_SUCCESS_CHANGED":
            console.log(action.value);
            return {
                ...state,
                confirmUpdateWarehouseBinData: false,
            };
        case "CONFIRM_UPDATE_WAREHOUSE_BIN_DATA_UNSUCCESS":
            return {
                ...state,
                loadingconfirmUpdateWarehouseBinData: false,
                confirmUpdateWarehouseBinData: false,
            };
        case "WAREHOUSE_ITEM_DATA":
            return {
                ...state,
                WarehouseItemData: action.value,
            };

        case "WAREHOUSE_ITEM_DATA_GROUPED_BY_WAREHOUSE":
            return {
                ...state,
                WarehouseItemDataGroupedByWarehouse: action.value,
            };

        case "WAREHOUSE_ITEM_QUANTITY_DATA_FOR_WAREHOUSE":
            return {
                ...state,
                WarehouseSelectedItemData: action.value,
            };
        case "WAREHOUSE_ITEM_QUANTITY_DATA_FOR_SELECTED_WAREHOUSE":
            return {
                ...state,
                SelectedWarehouseItemData: action.value,
            };

        case "WAREHOUSE_PRODUCTION_ORDERLINE_ITEM_QUANTITY_DATA_FOR_SELECTED_WAREHOUSE_BIN":
            return {
                ...state,
                SelectedWarehouseBinItemData: action.value,
            };

        case "WAREHOUSEBIN_ITEM_QUANTITY_DATA_FOR_SELECTED_ITEM":
            return {
                ...state,
                SelectedItemDataForAllWarehouseBin: action.value,
            };

        case "LOADING_SWAPING_ITEM_WAREHOUSE_BIN":
            return {
                ...state,
                loadingItemWarehouseBinSwap: true,
            };

        case "ITEM_WAREHOUSE_BIN_SWAPPED_SUCCESS":
            return {
                ...state,
                loadingItemWarehouseBinSwap: false,
                ItemWarehouseBinSwapped: true,
            };

        case "CHANGE_ITEM_WAREHOUSE_BIN_SWAPPED_SUCCESS":
            console.log(action.value);
            return {
                ...state,
                ItemWarehouseBinSwapped: false,
            };
        case "ITEM_WAREHOUSE_BIN_SWAPPED_FAILED":
            return {
                ...state,
                loadingItemWarehouseBinSwap: false,
                ItemWarehouseBinSwapped: false,
            };

        case "PRODUCED_ITEM_DATA":
            return {
                ...state,
                ProduceItemData: action.value,
            };

        case "TRANSFER_ITEM_DATA":
            return {
                ...state,
                TransferItemData: action.value,
            };

        case "TRANSFER_ITEM_DATA_UNSUCCESS":
            return {
                ...newstate,
                loadingatoadddata: false,
            };
        case "TRANSFER_ITEM_DATA_LOADING":
            return {
                ...newstate,
                loadingatoadddata: true,
            };
        case "TRANSFER_ITEM_DATA_SUCCESS":
            return {
                ...newstate,
                loadingatoadddata: false,
            };

        case "STOCK_REPORT_ITEM_DATA":
            return {
                ...state,
                StockReportData: action.value,
            };

        case "STOCK_REPORT_ITEM_DATA_UNSUCCESS":
            return {
                ...newstate,
                StockReportData: [],
            };

        case "GET_ITEM_HISTORY_REPORT":   
            return {
                ...newstate,
                ItemHistoryModalState: true,
                ItemHistoryData: action.value.reportList,
                ItemHistoryExcelData: action.value.execlList,
            }; 
            
        case "CHANGE_CLOSE_ITEM_HISTORY_MODAL_STATE":
            return {
                ...newstate,
                ItemHistoryModalState: action.value,
            };

        case "GET_STORED_RM_LINE_FOR_PRODUCTION":
            return {
                ...state,
                getStoredRMLineData: action.value,
            };

        case "GET_ITEM_MATERIAL_DATA":
            return {
                ...state,
                getItemMaterialData: action.value,
            };

        case "GET_STORED_RM_FOR_ITEM_CONVERT":
            return {
                ...state,
                getstoredRMItemConvert: action.value,
            };       

        case "GET_RM_REMAIN_RESERVED":
            return {
                ...state,
                getAllRawMaterialsRemainReserved: action.value,
            };

        case "GET_STORED_RM_HISTORY":   
            return {
                ...newstate,
                storedRMHistoryModalState: true,
                storedRMHistoryData: action.value,
            }; 

        case "CHANGE_CLOSE_STORED_RM_HISTORY_MODAL_STATE":
            return {
                ...newstate,
                storedRMHistoryModalState: action.value,
            };

        case "GET_RESERVED_STORED_RM_FOR_PRODUCTION":
            return {
                ...state,
                getReservedStoredRMData: action.value,
            };

        case "GET_RESERVED_STORED_RM_FOR_PRODUCTION_STEP":
            return {
                ...state,
                getProductionStepReservedStoredRM: action.value,
            };

        case "GET_GRN_USAGE":   
            return {
                ...newstate,
                grnUsageModalState: true,
                grnUsageData: action.value,
            }; 

        case "CHANGE_CLOSE_GRN_RAW_MATERIAL_USAGE_STATE":
            return {
                ...newstate,
                grnUsageModalState: action.value,
            };
        case "GET_ACCOUNT_TRANSFER_DATA":
            return {
                ...newstate,
                getAccountTransferData: action.value,
            };
        case "ADD_ACCOUNT_TRANSFER_SUCCESS":
            return {
                ...state,
                addAccountTransfer: true,
                loadingAccountTransfer: false,
            };

        case "ADD_ACCOUNT_TRANSFER_LOADING":
            return {
                ...state,
                loadingAccountTransfer: true,
            };

        case "ADD_ACCOUNT_TRANSFER_UNSUCCESS":
            return {
                ...state,
                addAccountTransfer: false,
                loadingAccountTransfer: false,
            };

        case "CHANGE_ACCOUNT_TRANSFER_SUCCESS_STATUS":
            return {
                ...state,
                addAccountTransfer: false,
            };

        case "GET_CURRENT_DIESEL_DATA":
            return {
                ...state,
                getCurrentDieselValue: action.value,
            };
        case "GET_STORED_RM_REPORT_DATA":
            return {
                ...state,
                RawMaterialReportData: action.value,      
            };
        default:
            break;
    }
    return newstate;
};
export default reducer;
