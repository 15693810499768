const initialState = {
    isLoading: false,
    isDataLoading: false,
    isAuthenicated: null,
    RegStatus: false,
    addUGrpStatus: false,
    addRoleStatus: false,
    addProgramStatus: false,
    RegMenuStatus: false,
    RegMenuLevelStatus: false,
    RegUPermissionStatus: false,
    RegRolePermissionMappingStatus: false,
    user: null,
    changedpassword: false,
    changedData: null,

    imagePath: null,
};

const reducer = (state = initialState, action) => {
    const newstate = {
        ...state,
    };

    switch (action.type) {
        case "USER-LOADING":
            return {
                ...state,
                isLoading: true,
            };
        case "USER_LOADED":
            return {
                ...state,
                user: action.value,
                isAuthenicated: true,
                isLoading: false,
                imagePath: action.value.imagePath,
            };
        case "DATA-LOADING":
            return {
                ...state,
                isDataLoading: true,
            };
        case "DATA-LOADED":
            return {
                ...state,
                isDataLoading: false,
            };

        case "LOGIN_SUCCESS":
            return {
                ...state,
                ...action.value,
                isAuthenicated: true,
                isLoading: false,
            };

        case "REGISTER_SUCCESS":
            return {
                ...state,
                RegStatus: true,
            };

        case "USER_PERMISSION_ADDED":
            return {
                ...state,
                RegUPermissionStatus: true,
            };
        case "CHANGE_USER_PERMISSION_REG_STATE":
        case "USER_PERMISSION_ADDED_FAILED":
            return {
                ...state,
                RegUPermissionStatus: false,
            };

        case "CHANGE_REG_STATE":
            return {
                ...state,
                RegStatus: false,
            };
        case "REGISTER_USER_GROUP_SUCCESS":
            return {
                ...state,
                addUGrpStatus: true,
            };
        case "REGISTER_USER_GROUP_FAIL":
            return {
                ...state,
                addUGrpStatus: false,
            };
        case "REGISTER_USER_ROLE_SUCCESS":
            return {
                ...state,
                addRoleStatus: true,
            };
        case "CHANGE_REGISTER_USER_ROLE_SUCCESS":
            return {
                ...state,
                addRoleStatus: false,
            };
        case "REGISTER_USER_ROLE_FAIL":
            return {
                ...state,
                addRoleStatus: false,
            };
        case "REG_PROGRAM_SUCCESS":
            return {
                ...state,
                addProgramStatus: true,
            };
        case "CHANGE_REG_PROGRAM_SUCCESS":
            return {
                ...state,
                addProgramStatus: false,
            };
        case "REG_PROGRAM_FAIL":
            return {
                ...state,
                addProgramStatus: false,
            };
        case "REG_MENU_SUCCESS":
            return {
                ...state,
                RegMenuStatus: true,
            };
        case "CHANGE_REG_MENU_SUCCESS":
            return {
                ...state,
                RegMenuStatus: false,
            };
        case "REG_MENU_FAIL":
            return {
                ...state,
                RegMenuStatus: false,
            };

        // reg role mapping
        case "ROLE_MAPPING_SUCCESS":
            return {
                ...state,
                RegRolePermissionMappingStatus: true,
            };

        case "CHANGE_ROLE_MAPPING_SUCCESS":
            return {
                ...state,
                RegRolePermissionMappingStatus: false,
            };

        case "ROLE_MAPIING_FAIL":
            return {
                ...state,
                RegRolePermissionMappingStatus: false,
            };

        case "REG_MENULEVEL_SUCCESS":
            return {
                ...state,
                RegMenuLevelStatus: true,
            };

        case "MENU_LEVEL_TOGGLE_STATE":
            return {
                ...state,
                RegMenuLevelStatus: false,
            };
        case "REG_MENULEVEL_FAIL":
            return {
                ...state,
                RegMenuLevelStatus: false,
            };

        case "AUTH_ERROR":
        case "LOGIN_FAIL":
        case "LOGOUT_SUCCESS":
            return {
                ...state,
                token: null,
                user: null,
                isAuthenicated: false,
                isLoading: false,
            };

        case "CHANGED_PASSWORD_SUCESS":
            return {
                ...state,
                changedpassword: true,
            };
        case "CHANGED_PASSWORD_SUCESS_STATUS":
            return {
                ...state,
                changedpassword: false,
            };

        case "CHANGED_PASSWORD_UNSUCESS":
            return {
                ...state,
                changedpassword: false,
            };

        default:
            break;
    }
    return newstate;
};
export default reducer;
