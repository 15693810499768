import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./client/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./index.css";
import "./custom-prime.css";
import App from "./client/App";
import * as serviceWorker from "./client/serviceWorker";
import store from "./client/store";
import { Provider } from "react-redux";
import "./client/language/i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./client/language/i18n";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Router>
                <Switch>
                    <App />
                </Switch>
            </Router>
        </I18nextProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
