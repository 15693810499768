import axios from "axios";
import { returnErrors } from "./errorActions";
import { getMenuLevel } from "../actions/tableActions";

import { showusers, getUserGroup, getUserRoles, getAssignedRoles, getProgram, getMenu, getRoleMapping, getUserPermission } from "./tableActions";
import { toast } from "react-toastify";

export const loadUser = (data = null) => (dispatch, getState) => {
    // User loading
    dispatch({ type: "USER_LOADING " });

    if (data) {
        dispatch({
            type: "USER_LOADED",
            value: data,
        });
    } else {
        axios
            .get("/api/auth/TokenValid")
            .then((res) => {
                console.log(res.request.responseURL);
                console.log(res.config.url);
                let hostUrl = res.request.responseURL.replace(res.config.url, "");

                localStorage.setItem("host", hostUrl);
                dispatch({
                    type: "USER_LOADED",
                    value: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: "AUTH_ERROR",
                });
                localStorage.removeItem("host");
            });
    }
};

// Login User
export const login = ({ UserName, Password }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    // Request body
    const body = JSON.stringify({ UserName, Password });
    axios
        .post("/api/auth", body, config)
        .then((res) => {
            localStorage.setItem("permission", JSON.stringify(res.data.permission));
            localStorage.setItem("menuData", JSON.stringify(res.data.menuData));
            localStorage.setItem("parameterData", JSON.stringify(res.data.parameterData));
            dispatch({
                type: "LOGIN_SUCCESS",
                value: res.data,
            });

            setTimeout(() => {
                dispatch(loadUser(res.data.user)); //result is now available
            }, 1000);
        })
        .catch((err) => {
            dispatch({
                type: "LOGIN_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "LOGIN_FAIL"));
        });
};

// Register User
export const register = (data) => (dispatch, getState) => {
    axios
        .post("/api/users", data)
        .then((res) => {
            dispatch({
                type: "REGISTER_SUCCESS",
            });

            toast.success("User Registered Successfully!");
            dispatch(showusers());
            dispatch({
                type: "CHANGE_REG_STATE",
            });
        })
        .catch((err) => {
            if (err.response.data === "tokenExpired") {
                dispatch({
                    type: "AUTH_ERROR",
                });
            } else {
                dispatch(returnErrors(err.response.data, err.response.status, "REGISTER_FAIL"));
                dispatch({
                    type: "REGISTER_FAIL",
                });
            }
        });
};

// Register Usergroup
export const regUserGroup = ({ Name }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        Name,
    });

    axios
        .post("/api/users/AddUserGroup", body, config)
        .then((res) => {
            dispatch({
                type: "REGISTER_USER_GROUP_SUCCESS",
            });
            toast.success("User Group Registered Successfully!");
            dispatch(getUserGroup());
        })
        .catch((err) => {
            dispatch({
                type: "REGISTER_USER_GROUP_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REGISTER_USER_GROUP_FAIL"));
        });
};

// Register UserRole
export const regUserRole = ({ Name }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        Name,
    });

    axios
        .post("/api/users/AddUserRole", body, config)
        .then((res) => {
            dispatch({
                type: "REGISTER_USER_ROLE_SUCCESS",
            });
            toast.success("User Role Registered Successfully!");
            dispatch(getUserRoles());
            dispatch({
                type: "CHANGE_REGISTER_USER_ROLE_SUCCESS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "REGISTER_USER_ROLE_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REGISTER_USER_ROLE_FAIL"));
        });
};

// add UserRoleAssinment
export const regUserRoleAssignment = ({ id, roleIDs }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        id,
        roleIDs,
    });

    axios
        .post("/api/users/UserRoleAssignment", body, config)
        .then((res) => {
            dispatch({
                type: "USER_ROLE_ASSIGNMENT_SUCCESS",
            });
            dispatch(getAssignedRoles(id));
            toast.success("User Role Assigned Successfully!");
        })
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status, "USER_ROLE_ASSIGNMENT_FAIL"));
            toast.error(err.response.data);
        });
};

// add Programme
export const regProgram = ({ Name, url }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        Name,
        url,
    });

    axios
        .post("/api/users/regProgram", body, config)
        .then((res) => {
            dispatch({
                type: "REG_PROGRAM_SUCCESS",
            });
            dispatch(getProgram());
            toast.success("Program Added Successfully!");
            dispatch({
                type: "CHANGE_REG_PROGRAM_SUCCESS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "REG_PROGRAM_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REG_PROGRAM_FAIL"));
        });
};

// add Menu
export const regMenu = ({ MenuName, levelId, programId, ParentId, parentIndex, childIndex, icon }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        MenuName,
        levelId,
        programId,
        ParentId,
        parentIndex,
        childIndex,
        icon,
    });

    axios
        .post("/api/users/regMenu", body, config)
        .then((res) => {
            dispatch({
                type: "REG_MENU_SUCCESS",
            });
            dispatch(getMenu());
            toast.success("Menu Added Successfully!");
            dispatch({
                type: "CHANGE_REG_MENU_SUCCESS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "REG_MENU_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REG_MENU_FAIL"));
        });
};

//add menu level
export const regMenuLevel = ({ Menulevel, MenulevelName }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        Menulevel,
        MenulevelName,
    });
    axios
        .post("/api/users/regMenuLevel", body, config)
        .then((res) => {
            dispatch({
                type: "REG_MENULEVEL_SUCCESS",
            });
            dispatch(getMenuLevel());
            dispatch({ type: "MENU_LEVEL_TOGGLE_STATE" });
            toast.success("Menu level  Added Successfully!");
        })
        .catch((err) => {
            dispatch({
                type: "REG_MENULEVEL_FAIL",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REG_MENULEVEL_FAIL"));
        });
};
// add RollMapping
export const regRoleMapping = (rollMapedData, prompt, state) => (dispatch) => {
    const { roleId, ProgramId, CreateSwitchedData, ReadSwitchedData, UpdateSwitchedData, DeleteSwitchedData } = rollMapedData;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        prompt,
        roleId,
        ProgramId,
        CreateSwitchedData,
        ReadSwitchedData,
        UpdateSwitchedData,
        DeleteSwitchedData,
    });

    axios
        .post("/api/users/regRoleMapping", body, config)
        .then((res) => {
            dispatch({
                type: "ROLE_MAPPING_SUCCESS",
            });

            dispatch(getRoleMapping(roleId, state));
            toast.success("Roles Mapped Successfully!");

            dispatch({
                type: "CHANGE_ROLE_MAPPING_SUCCESS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "ROLE_MAPIING_FAIL",
            });

            dispatch(returnErrors(err.response.data, err.response.status, "ROLE_MAPIING_FAIL"));
        });
};

// add RollMapping
export const regUserPermission = ({ UserId, ProgramId, CreateSwitchedData, ReadSwitchedData, UpdateSwitchedData, DeleteSwitchedData, SpecialSwitchedData }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({
        UserId,
        ProgramId,
        CreateSwitchedData,
        ReadSwitchedData,
        UpdateSwitchedData,
        DeleteSwitchedData,
        SpecialSwitchedData,
    });

    axios
        .post("/api/users/regUserPermission", body, config)
        .then((res) => {
            dispatch({
                type: "USER_PERMISSION_ADDED",
            });
            dispatch(getUserPermission(UserId));
            toast.success("User Permission Added Successfully!");
            dispatch({
                type: "CHANGE_USER_PERMISSION_REG_STATE",
            });
        })
        .catch((err) => {
            dispatch({
                type: "USER_PERMISSION_ADDED_FAILED",
            });
            dispatch(returnErrors(err.response.data, err.response.status, "REG_USER_FAIL"));
        });
};

// Logout User
export const logout = () => (dispatch) => {
    axios
        .get("/api/auth/logout")
        .then((res) => {
            dispatch({
                type: "LOGOUT_SUCCESS",
            });
        })
        .catch((err) => {
            dispatch({
                type: "AUTH_ERROR",
            });
        });
};

//change password

export const changePassword = ({ currentpw, newpw, confirmpw }) => (dispatch, getState) => {
    // Headers

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    if (getState().auth.user) {
        const user = getState().auth.user;
        const userId = user.id;
        const body = JSON.stringify({
            currentpw,
            newpw,
            confirmpw,
            userId,
        });

        axios
            .post("/api/auth/changepassword", body, config)
            .then((res) => {
                dispatch({
                    type: "CHANGED_PASSWORD_SUCESS",
                });
                dispatch({
                    type: "CHANGED_PASSWORD_SUCESS_STATUS",
                });
                toast.success("Password reset Successfully!");
            })
            .catch((error) => {
                dispatch({
                    type: "CHANGED_PASSWORD_UNSUCESS",
                });

                dispatch(returnErrors(error.response.data, error.response.status, "CHANGED_PASSWORD_UNSUCESS"));
            });
    }
};

//change user passwords manully
export const changeUserPasswordManually = (data, callback) => (dispatch) => {
    axios
        .post("/api/auth/changeUserpasswordManually", data)
        .then((res) => {
            callback();
            toast.success("Password changed successfully!");
        })
        .catch((error) => {
            callback(error.response.data);
            dispatch(returnErrors(error.response.data, error.response.status, "CHANGED_PASSWORD_UNSUCESS"));
        });
};
