const initialState = {
    PurchaseOrderList: [],
    PurchaseOrderListNotEdited: [],
    PoExistInStore: false,
    PoUpdatingOrView: true,
    PoNumber: null,
    PoId: 1,
    SupplierPicker: [],
    SupplierAddressPicker: [],
    PoItemList: [],
    SavePoItemList: [
        { itemId: "100", qty: 3, unitPrice: 2000 },
        { itemId: "200", qty: 4, unitPrice: 2000 },
    ],
    PurchaseOrderForm: null,
    //PurchaseOrderForm : { supplierId: "", supplierAddress: "", orderDate: "2020-04-25", poNumber: "", reference: "def", remark: "def" },
    SupplierId: null,
    // PurchaseOrderFormT : {
    //     supplierId: "100",supplierName:"Sup1",
    //     supplierAddress: "100", supplierAddressLabel: "Sup 1 Add",
    //     orderDate: "2020-04-25", poNumber: "10025", reference: "Server ref", remark: "Server rem"
    // },
    RawMaterialsList: [
        { itemId: "1", itemName: "Others 4", unit: "kg", unitPrice: "1100" },
        { itemId: "2", itemName: "Others 3", unit: "kg", unitPrice: "2100" },
        { itemId: "3", itemName: "Others 1", unit: "kg", unitPrice: "3100" },
    ],
    GrnItemList: [
        {
            itemId: "4001",
            itemName: "FromServer 4",
            unit: "kg",
            qty: "1",
            unitPrice: "1100",
            amount: "1000",
            rqty: "1",
            remark: "",
        },
        {
            itemId: "3001",
            itemName: "FromServer 3",
            unit: "kg",
            qty: "2",
            unitPrice: "2100",
            amount: "1000",
            rqty: "1",
            remark: "",
        },
        {
            itemId: "2001",
            itemName: "FromServer 1",
            unit: "kg",
            qty: "3",
            unitPrice: "3100",
            amount: "1000",
            rqty: "1",
            remark: "",
        },
    ],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        //USED : PO_DELETE
        case "PO_DELETE":
            return {
                ...state,
                PurchaseOrderList: [
                    ...state.PurchaseOrderList.filter(
                        (item) => item.poNumber !== action.value
                    ),
                ],
            };

        //USED : delete
        case "PO_DELETE_ITEM":
            let poListAfterDelete = [
                ...state.PoItemList.filter(
                    (item) => Number(item.itemId) !== Number(action.value)
                ),
            ];
            return {
                ...state,
                PoItemList: poListAfterDelete,
            };

        case "PO_GET_SUPPLIER_CONTACTNUMBER":
            let poForm = newstate.PurchaseOrderForm || {};
            poForm.supplierContactNumber = action.value.contactNumber;

            return {
                ...newstate,
                PurchaseOrderForm: poForm,
            };

        case "PO_GET_SUPPLIER_BY_ID":
            poForm = newstate.PurchaseOrderForm || {};
            poForm.supplierTelNumber = action.value[0].telephoneNumber ? action.value[0].telephoneNumber : "";
            poForm.supplierAddress =  action.value[0].supplierAddress ? action.value[0].supplierAddress : ""

            return {
                ...newstate,
                PurchaseOrderForm: poForm,
            };


        case "PO_GET_SUPPLIER_MATERIALS":
            let supplierList = action.value;

            let newPoList = [];
            supplierList.map((item, i) => {
                // exclude where materialType = 2 anditem.isDistribution = 0 || null
                if (
                    item.materialType === 2 &&
                    (item.isDistribution === null || item.isDistribution === 0)
                ) {
                    // skip this iteration
                    return true;
                }

                newPoList.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    qty: 0,
                    unitPrice: item.unitPrice,
                    amount: 0.0,
                    pqty: 0,
                    AccountParameters: item.AccountParameters,
                });
                return true;
            });

            //newstate.PoItemList = newPoList;
            console.log("PO_GET_SUPPLIER_MATERIALS", newPoList);
            return {
                ...newstate,
                PoItemList: newPoList,
            };

        case "PUSH_PO_ITEMS_TO_STORE":
            //console.log("reducer...PUSH_PO_ITEMS_TO_STORE", action.value);
            newstate.PoItemList = action.value;

            return {
                ...newstate,
                PoItemList: [...newstate.PoItemList],
            };

        case "ADD_WAREHOUSE_SUCCESS":
            return {
                ...state,
                addWarehouseStatus: true,
                loadingWarehouse: false,
            };

        case "GET_PO_DATA":
            console.log("GET_PO_DATA", action.value);

            let form = {
                purchaseOrderId: action.value.poFormData.purchaseOrderId,
                supplierId: action.value.poFormData.supplierId,
                supplierName: action.value.poFormData.supplierName,
                supplierContact: action.value.poFormData.supplierContact,
                supplierContactId: action.value.poFormData.supplierContactId,
                supplierContactNumber: action.value.poFormData.supplierContactNumber,
                orderDate: action.value.poFormData.orderDate,
                reference: action.value.poFormData.reference,
                remark: action.value.poFormData.remark,
                isClosed: action.value.poFormData.isClosed,
                poNumber: action.value.poFormData.poNumber,
                dueDate: action.value.poFormData.dueDate,
                totalValue: action.value.poFormData.totalValue,
                currentDue: action.value.poFormData.currentDue,
                isGRNAvailable: action.value.poFormData.isGRNAvailable,
                supplierTelNumber: action.value.poFormData.supplierTel,
                supplierAddress: action.value.poFormData.supplierAddress,
                isPurchasedService: action.value.poFormData.isPurchasedService,
                isSupplierActive: Boolean(
                    Number(action.value.poFormData.isSupplierActive)
                ),
            };

            return {
                ...state,
                PurchaseOrderForm: form,
                PoItemList: action.value.poItemList,
                PurchaseOrderListNotEdited: action.value.poItemList,
                editMode: true,
                Values: action.value,
            };

        case "GET_PO_PRINT_DATA":
            return {
                ...state,
                PurchaseOrderPrint: action.value,
            };

        //UPDATE_PO_ITEMS
        case "UPDATE_PO_ITEMS":
            //console.log("reducer...UPDATE_PO_ITEMS", action.value);
            newstate.PoItemList = action.value;

            return {
                ...newstate,
                PoItemList: [...newstate.PoItemList],
            };

        //SET_PO_UPDATEFORM : Used in update form Link
        case "SET_PO_UPDATEFORM":
            console.log("SET_PO_UPDATEFORM", action.value);
            return {
                ...state,
                PurchasenOrderForm: {
                    purchaseOrderId: action.value.purchaseOrderId,
                    supplierId: action.value.supplierId,
                    supplierName: action.value.supplierName,
                    supplierContact: action.value.supplierContact,
                    supplierContactId: action.value.supplierContactId,
                    supplierContactNumber: action.value.supplierContactNumber,
                    orderDate: action.value.orderDate,
                    reference: action.value.reference,
                    remark: action.value.remark,
                    isClosed: action.value.isClosed === 0 ? false : true,
                    grnShowRead: action.value.grnShowRead,
                    poShowEdit: action.value.poShowEdit,
                    poShowRead: action.value.poShowRead,
                    poNumber: action.value.poNumber,
                    dueDate: action.value.dueDate,
                    totalValue: action.value.totalValue,
                    currentDue: action.value.currentDue,
                    isGRNAvailable: action.value.isGRNAvailable,
                    supplierTelNumber: action.value.supplierTel,
                    supplierAddress: action.value.supplierAddress,
                    isPurchasedService: action.value.isPurchasedService,
                    isSupplierActive: Boolean(
                        Number(action.value.isSupplierActive)
                    ),
                },
                PoItemList: action.value.poItemList,
                PoNumber: action.value.poNumber,
                SupplierId: action.value.supplierId,
                PurchaseOrderListNotEdited: action.value.poItemList,
                editMode: true,
            };

        case "PO_PUSH_PONUMBER":
            return {
                ...state,
                PurchasenOrderForm: {
                    purchaseOrderId: action.value.purchaseOrderId,
                    supplierId: action.value.supplierId,
                    supplierName: action.value.supplierName,
                    supplierContact: action.value.supplierContact,
                    supplierContactId: action.value.supplierContactId,
                    supplierContactNumber: action.value.supplierContactNumber,
                    orderDate: action.value.orderDate,
                    reference: action.value.reference,
                    remark: action.value.remark,
                    isClosed: action.value.isClosed === 0 ? false : true,
                    grnShowRead: action.value.grnShowRead,
                    poShowEdit: action.value.poShowEdit,
                    poShowRead: action.value.poShowRead,
                    poNumber: action.value.poNumber,
                    dueDate: action.value.dueDate,
                    totalValue: action.value.totalValue,
                    currentDue: action.value.currentDue,
                    isGRNAvailable: action.value.isGRNAvailable,
                    supplierTelNumber: action.value.supplierTel,
                    supplierAddress: action.value.supplierAddress,
                    isPurchasedService: action.value.isPurchasedService,
                    isSupplierActive: Boolean(
                        Number(action.value.isSupplierActive)
                    ),
                },

                PoNumber: action.value.poNumber,
                SupplierId: action.value.supplierId,
                editMode: true,
            };

        case "GET_ALL_PO_MATERIALS":
            return {
                ...newstate,
                RawMaterialsList: action.value,
            };

        case "SET_PO_FORM_DATA":
            return {
                ...newstate,
                PurchaseOrderForm: action.value,
            };

        case "SET_PO_PRINT_STATE":
            return {
                ...newstate,
                PurchaseOrderPrintState: action.value,
            };

        case "PO_ADD_ITEMS":
            let addedList = action.value;

            let newAddedList = [...newstate.PoItemList];
            addedList.map((item, i) => {
                newAddedList.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    unitPrice: parseFloat(item.unitPrice),
                    qty: 0,
                    amount: 0,
                    status: 1,
                    cellEdited: true,
                    AccountParameters: item.AccountParameters,
                });
                return true;
            });

            newstate.PoItemList = newAddedList;
            //console.log("reducer...PO_ADD_ITEMS", newstate.PoItemList);
            return {
                ...newstate,
                PoItemList: [...newstate.PoItemList],
            };

        case "GET_PO_ITEMS":
            //console.log("reducer...GET_PO_ITEMS");
            return {
                ...state,
                PoItemList: [...state.PoItemList],
            };

        case "CLOSE_PURCHASE_ORDER_SUCCESS":
            //console.log("reducer...GET_PO_ITEMS");
            return {
                ...state,
                PoItemList: [...state.PoItemList],
            };

        case "CLOSE_PURCHASE_ORDER_FAIL;":
            //console.log("reducer...GET_PO_ITEMS");
            return {
                ...state,
                PoItemList: [...state.PoItemList],
            };

        case "RESET_PO_FORM":
            console.log("clear........................");
            return {
                ...state,
                PoExistInStore: false,
                PurchaseOrderForm: null,
                PoNumber: null,
                PoItemList: [],
            };

        case "SAVE_PURCHASE_ORDER_SUCCESS":
            return {
                ...state,
                PoNumber: action.value.poNumber,
                PoExistInStore: true,
                //PoItemList: []
            };

        case "GET_SUPPLIERS":
            return {
                ...state,
                SupplierPicker: action.value,
            };

        //update PO form
        case "UPDATE_PURCHASE_ORDER":
            return {
                ...state,
                //PoExistInStore : !action.value.newPo ? true : false,
                PoItemList: action.value.poItemList,
                PurchaseOrderForm: null,
                //SupplierPicker : action.value.poItemList,
            };

        //Open new PO form
        case "CREATE_PURCHASE_ORDER":
            return {
                ...state,
                //PoExistInStore : !action.value.newPo ? true : false,
                PoItemList: [],
                PurchaseOrderForm: null,
                //SupplierPicker : action.value.poItemList,
            };

        case "GET_SUP_ADDRESSES":
            newstate.SupplierAddressPicker = action.value;
            return {
                ...newstate,
                SupplierAddressPicker: [...newstate.SupplierAddressPicker],
            };

        case "GET_PO_LIST":
            return {
                ...state,
                PurchaseOrderList: action.value,
            };

        case "GET_SUPPLIER_ASSIGNED_SURCHARGE_DATA":
            return {
                ...state,
                supplierAssignedSurChargeData: action.value,
            };

        case "GET_SUPPLIER_TAX_OPTION":
            return {
                ...newstate,
                supplierTaxOption: action.value,
            };

        case "GET_SUPPLIER_DISCOUNT":
            newstate.SupplierDiscount = action.value;
            return {
                ...newstate,
                SupplierDiscount: action.value,
            };

        case "PUSH_PO_TAX_OPTION_TO_STORE":
            newstate.PoTaxOptionList = action.value;
            return {
                ...newstate,
                PoTaxOptionList: [...newstate.PoTaxOptionList],
            };

        case "PUSH_PO_SURCHARGE_BEFORE_TAX_TO_STORE":
            newstate.PoSurchargeBeforeTaxList = action.value;
            return {
                ...newstate,
                PoSurchargeBeforeTaxList: [...newstate.PoSurchargeBeforeTaxList],
            };

        case "PUSH_PO_SURCHARGE_AFTER_TAX_TO_STORE":
            newstate.PoSurchargeAfterTaxList = action.value;
            return {
                ...newstate,
                PoSurchargeAfterTaxList: [...newstate.PoSurchargeAfterTaxList],
            };

        case "PUSH_PO_SUPPLIER_DISCOUNT_TO_STORE":
            newstate.SupplierDiscount = action.value;
            return {
                ...newstate,
                SupplierDiscount: action.value,
            };
            
        case "PUSH_PO_TOTAL_TO_STORE":
            newstate.FinalTotal = action.value;
            return {
                ...newstate,
                FinalTotal: action.value,
            };

        case "PUSH_PO_EARNINGS_BEFORE_TAX_TO_STORE":
            newstate.EarningsBeforeTax = action.value;
            return {
                ...newstate,
                EarningsBeforeTax: action.value,
            };

        case "PUSH_PO_EARNINGS_AFTER_TAX_TO_STORE":
            newstate.EarningsAfterTax = action.value;
            return {
                ...newstate,
                EarningsAfterTax: action.value,
            };

        case "GET_SERVICE_ACC_PARAMS":
            newstate.serviceAccoutParameters = action.value;

            return {
                ...newstate,
                serviceAccoutParameters: [...newstate.serviceAccoutParameters],
            };


        default:
            break;
    }

    return state;
};
export default reducer;
