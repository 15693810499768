const initialState = {
    users: null,
    usergroup: null,
    Program: null,
    roles: null,
    roleUpdateStatus: false,
    roleUpdateLoadingStatus: false,
    MenuLevel: null,
    MenuData: null,
    UserPermissionData: null,
    rolePermissionMapData: null,
    assignedRolesOriginal: [],
    assignedRoles: [],
    assignedRolesLoded: false,
    assignedrolesUpdated: false,
    MenuLevelUpdate: false,
    MenuUpdateStatus: false,
    UserNamesForPermission: null,
    RoleNamesForPermission: null,
    userPermissionDetails: null,
    UpdateRolePermissionMappingStatus: false,
    UserData: null,
    RoleData: null,
};

const reducer = (state = initialState, action) => {
    const newstate = {
        ...state,
    };

    switch (action.type) {
        case "USER_SHOWED":
            return {
                ...state,
                users: action.value,
            };

        case "GET_USER_GROUP":
            return {
                ...state,
                usergroup: action.value,
            };
        case "GET_USER_ROLES":
            return {
                ...state,
                roles: action.value,
            };
        case "GET_ASSIGNED_ROLES":
            return {
                ...state,
                assignedRolesOriginal: action.value,
                assignedRoles: action.value,
                assignedRolesLoded: true,
            };
        case "USER_ROLE_UPDATED":
            return {
                ...state,
                roleUpdateStatus: true,
                roleUpdateLoadingStatus: false,
            };
        case "UPDATE_ROLE_LOADING":
            return {
                ...state,
                roleUpdateLoadingStatus: true,
            };
        case "CHANGE_USER_ROLES_UPDATE_STATUS":
            return {
                ...state,
                roleUpdateStatus: false,
            };
        case "UPDATE_USER_ROLES_UNSUCCESS":
            return {
                ...state,
                roleUpdateStatus: false,
                roleUpdateLoadingStatus: false,
            };

        // update role mapping
        case "UPDATE_ROLE_MAPPING_SUCCESS":
            return {
                ...state,
                UpdateRolePermissionMappingStatus: true,
            };

        case "UPDATE_CHANGE_ROLE_MAPPING_SUCCESS":
            return {
                ...state,
                UpdateRolePermissionMappingStatus: false,
            };

        case "UPDATE_ROLE_MAP_FAIL":
            return {
                ...state,
                UpdateRolePermissionMappingStatus: false,
            };

        case "GET_PROGRAM":
            return {
                ...state,
                Program: action.value,
            };
        case "GET_MENU_LEVEL":
            return {
                ...state,
                MenuLevel: action.value,
            };

        case "GET_MENU":
            return {
                ...state,
                MenuData: action.value,
            };
        case "GET_USER_PERMISSION":
            return {
                ...state,
                UserPermissionData: action.value,
            };
        case "GET_USER_ID":
            console.log(action.value);
            return {
                ...state,
                getWipId: action.value,
            };

        case "GET_USER_ID2":
            console.log(action.value);
            return {
                ...state,
                getWipId2: action.value,
            };

        case "GET_USER_NAMES_FOR_PERMISSION":
            return {
                ...state,
                UserNamesForPermission: action.value,
            };
        case "GET_USER_DETAILS_FOR_PERMISSION":
            return {
                ...state,
                userPermissionDetails: action.value,
            };
        case "ADD_ASSIGNED_ROLES":
            console.log(action.value);
            return {
                ...state,
                assignedRoles: action.value,
                assignedrolesUpdated: true,
                assignedRolesLoded: true,
            };
        case "GET_ROLE_PERMISSION_MAP":
            return {
                ...state,
                rolePermissionMapData: action.value,
            };
        case "GET_ROLE_DETAILS_FOR_PERMISSION":
            return {
                ...state,
                rolePermissionDetails: action.value,
            };
        case "GET_ROLE_NAMES_FOR_PERMISSION":
            return {
                ...state,
                RoleNamesForPermission: action.value,
            };
        case "USER_DELETED":
            console.log("ID I GOT" + action.value);
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.value),
            };
        case "USER_PERMISSION_DELETED":
            return {
                ...state,
                UserPermissionData: state.UserPermissionData.filter(
                    (user) => user.userPermissionId !== action.value
                ),
            };
        case "USER_GROUP_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                usergroup: state.usergroup.filter(
                    (userGroup) => userGroup.id !== action.value
                ),
            };
        case "ROLE_PERMISSION_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                rolePermissionMapData: state.rolePermissionMapData.filter(
                    (RolePermission) => RolePermission.id !== action.value
                ),
            };

        case "USER_ROLE_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                roles: state.roles.filter(
                    (userrole) => userrole.id !== action.value
                ),
            };
        case "PROGRAM_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                Program: state.Program.filter(
                    (Program) => Program.id !== action.value
                ),
            };
        case "MENU_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                MenuData: state.MenuData.filter(
                    (Menu) => Menu.id !== action.value
                ),
            };
        case "MENULEVEL_DELETED":
            console.log("ID I GOT " + action.value);
            return {
                ...state,
                MenuLevel: state.MenuLevel.filter(
                    (MenuLevel) => MenuLevel.id !== action.value
                ),
            };
        case "UPDATE_MENULEVEL_DATA_UNSUCCESS":
            return {
                ...state,
                MenuLevelUpdate: false,
            };
        case "MENU_UPDATED":
            return {
                ...state,
                MenuUpdateStatus: true,
            };
        case "CHANGE_MENU_UPDATED":
            return {
                ...state,
                MenuUpdateStatus: false,
            };
        case "MENU_UPDATE_FAILED":
            return {
                ...state,
                MenuUpdateStatus: false,
            };

        case "SET_USER_DATA":
            return {
                ...state,
                UserData: action.value,
            };

        case "SET_ROLE_DATA":
            return {
                ...state,
                RoleData: action.value,
            };

        default:
            break;
    }
    return newstate;
};
export default reducer;
