const initialState = {
    RawMaterialsList: [
        //{ "itemId": "1", "itemName": "Others 4", "unit": "kg", "unitPrice": "1100" },
        //{ "itemId": "2", "itemName": "Others 3", "unit": "kg", "unitPrice": "2100" },
        //{ "itemId": "3", "itemName": "Others 1", "unit": "kg", "unitPrice": "3100" }
    ],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };
    switch (action.type) {
        case "PO_GET_ALL_MATERIALS":
            let itemList = action.value;
            let newItemList = [];
            itemList.map((item, i) => {
                newItemList.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    unitPrice: item.unitPrice,
                    type: item.itemType,
                    materialType: item.materialType,
                    qty: 0, // let's initialize
                    rqty: 0, // let's initialize
                    isDistribution: item.isDistribution,
                    isWarehouseSkip: item.isWarehouseSkip,
                    AccountParameters: item.AccountParameters,
                });
                return true;
            });

            newstate.RawMaterialsList = newItemList;

            return {
                ...newstate,
                RawMaterialsList: [...newstate.RawMaterialsList],
            };

        default:
            break;
    }

    return state;
};
export default reducer;
