const initialState = {
    GrnItemList: [],
    PurchaseOrderDetails: {},
    GrnList: [],
    GrnNumber: null,
    GrnDetails: {},
    InspectionList: [],
    PurchaseOrderShouldClose: null,
    SupplierDiscount: null,
    GetSupplierRemainingPaymentData: null,
    PaymentVoucherModalState: false,
    MultipleInvoiceState:true,
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        //GRN_PUSH_INSPECTION
        case "GRN_PUSH_INSPECTION":
            newstate.InspectionList = action.value;
            return {
                ...newstate,
                InspectionList: [...newstate.InspectionList],
            };

        //GRN_GET_INSPECTION_LIST
        case "GRN_GET_INSPECTION_LIST":
            return {
                ...state,
                InspectionList: action.value,
            };

        case "GRN_ADDTO_INSPECTION_LIST":
            let newInspectionList = [...state.InspectionList];

            action.value.map((item, i) => {
                newInspectionList.push(item);
                return true;
            });

            return {
                ...state,
                InspectionList: newInspectionList,
            };

        //GRN_RESET
        case "GRN_RESET":
            return {
                ...state,
                GrnNumber: null,
                GrnItemList: [],
                GrnDetails: null,
                InspectionList: [],
            };

        //GRN_PUSH_GRNNUMBER
        case "GRN_PUSH_GRNNUMBER":
            return {
                ...state,
                GrnNumber: action.value.grnNumber,
                editMode: true,
            };

        //GRN_GET_LIST
        case "GRN_GET_LIST":
            return {
                ...state,
                GrnList: action.value,
            };

        case "GET_SUPPLIER_ASSIGNED_SURCHARGE_DATA":
            return {
                ...state,
                supplierAssignedSurChargeData: action.value,             
            };
 
        case "GET_SUPPLIER_TAX_OPTION":
            return {
                ...newstate,
                supplierTaxOption: action.value,
            };

        //USED : delete
        case "GRN_DELETE_ITEM":
            let afterDelete = [
                ...state.GrnItemList.filter(
                    (item) => Number(item.itemId) !== Number(action.value)
                ),
            ];
            return {
                ...state,
                GrnItemList: afterDelete,
            };

        //USED : GrnCreate GRN Add items
        case "GRN_ADD_ITEMS":
            let newList = [...state.GrnItemList];

            action.value.map((item, i) => {
                newList.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    qty: item.qty,
                    unitPrice: item.unitPrice,
                    amount: item.qty * item.unitPrice,
                    rqty: item.qty,
                    remark: "",
                    materialType: item.materialType,
                    isDistribution: item.isDistribution,
                    isWarehouseSkip: item.isWarehouseSkip,
                });
                return true;
            });

            return {
                ...state,
                GrnItemList: newList,
            };

        //USED : GrnDetails
        case "GRN_GET_DATA":

            let grnItemList = action.value.grnItemList;
            let inspectionList = [];
            grnItemList.map((item, key) => {
                if (item.inspectionList) {
                    inspectionList = inspectionList.concat(item.inspectionList);
                }

                return true;
            });

            return {
                ...state,
                GrnDetails: action.value.grnDetails,
                PODetails: action.value.poDetails,            
                GrnItemList: action.value.grnItemList,
                InspectionList: inspectionList,
            };

        //USED : GrnFromPo
        case "GET_GRN_DATA":
            let formData = action.value.poFormData;
            let itemLIst = [];
            action.value.poItemList.map((item, i) => {
                if (
                    item.materialType === 2 &&
                    (item.isDistribution === null || item.isDistribution === 0)
                ) {
                    // skip this iteration
                    return true;
                }

                itemLIst.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    qty: item.qty,
                    unitPrice: item.unitPrice,
                    amount: 0.0, //item.qty * item.unitPrice,
                    rqty: 0, //item.qty,
                    qtyToOrder: item.remainingQtyToOrder,
                    pqty: 0, //item.qty,
                    remark: "",
                    materialType: item.materialType,
                    isDistribution: item.isDistribution,
                });
                return true;
            });

            let poDataForGrn = { ...formData, poItemList: itemLIst };
            return {
                ...state,
                PurchaseOrderDetails: poDataForGrn,
                GrnItemList: itemLIst,
            };

        //USED : GrnFromPo
        case "PULLSTORE_GRNITEMS":
            return {
                ...state,
                GrnItemList: [...state.GrnItemList],
            };

        //USED : when supplier changed
        case "GRN_GET_SUPPLIER_MATERIALS":
            let supplierList = action.value;
            let newGrnList = [];
            supplierList.map((item, i) => {
                if (
                    item.materialType === 2 &&
                    (item.isDistribution === null || item.isDistribution === 0)
                ) {
                    // skip this iteration
                    return true;
                }

                newGrnList.push({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit,
                    qty: 0,
                    unitPrice: item.unitPrice,
                    amount: 0.0,
                    rqty: 0,
                    remark: "",
                    materialType: item.materialType,
                    isDistribution: item.isDistribution,
                    isWarehouseSkip: item.isWarehouseSkip,
                });

                return true;
            });

            newstate.GrnItemList = newGrnList;

            return {
                ...newstate,
                GrnItemList: [...newstate.GrnItemList],
            };

        case "ADD_GRN_ITEMS":
            return {
                ...state,
                GrnItemList: [...state.GrnItemList, action.value],
            };

        case "UPDATE_GRN_ITEMS":
            newstate.GrnItemList = action.value;
            return {
                ...newstate,
                GrnItemList: [...newstate.GrnItemList],
            };

        case "PUSH_GRN_ITEMS_TO_STORE":
            newstate.GrnItemList = action.value;
            return {
                ...newstate,
                GrnItemList: [...newstate.GrnItemList],
            };

        case "PUSH_GRN_TAX_OPTION_TO_STORE":
            newstate.GrnTaxOptionList = action.value;
            return {
                ...newstate,
                GrnTaxOptionList: [...newstate.GrnTaxOptionList],
            };

        case "PUSH_GRN_SURCHARGE_BEFORE_TAX_TO_STORE":
            newstate.GrnSurchargeBeforeTaxList = action.value;
            return {
                ...newstate,
                GrnSurchargeBeforeTaxList: [...newstate.GrnSurchargeBeforeTaxList],
            };

        case "PUSH_GRN_SURCHARGE_AFTER_TAX_TO_STORE":
            newstate.GrnSurchargeAfterTaxList = action.value;
            return {
                ...newstate,
                GrnSurchargeAfterTaxList: [...newstate.GrnSurchargeAfterTaxList],
            };

        case "SAVE_GRN_SUCCESS":
            return {
                ...state,
            };

        case "ADD_PAY_OUT":
            return {
                ...state,
                editMode: false,
                PaymentVoucherModalState: false,
                paymentVoucherDetails: action.value,
            };

        case "SAVE_GRN_FAIL":
            return {
                ...state,
            };

        //USED : GET_PURCHASE_ORDER_SHOULD_CLOSE
        case "GET_PURCHASE_ORDER_SHOULD_CLOSE":

            newstate.PurchaseOrderShouldClose = action.value;
            return {
                ...newstate,
                PurchaseOrderShouldClose: action.value,
            };

        case "GET_SUPPLIER_DISCOUNT":
                newstate.SupplierDiscount = action.value;
                return {
                    ...newstate,
                    SupplierDiscount: action.value,
                };

        case "PUSH_GRN_SUPPLIER_DISCOUNT_TO_STORE":
            newstate.SupplierDiscount = action.value;
            return {
                ...newstate,
                SupplierDiscount: action.value,
            };
            
        case "PUSH_GRN_TOTAL_TO_STORE":
                newstate.FinalTotal = action.value;
                return {
                    ...newstate,
                    FinalTotal: action.value,
                };

        case "PUSH_GRN_EARNINGS_BEFORE_TAX_TO_STORE":
                newstate.EarningsBeforeTax = action.value;
                return {
                    ...newstate,
                    EarningsBeforeTax: action.value,
                };

        case "PUSH_GRN_EARNINGS_AFTER_TAX_TO_STORE":
                newstate.EarningsAfterTax = action.value;
                return {
                    ...newstate,
                    EarningsAfterTax: action.value,
                };

        case "SET_PAY_OUT_SCHEDULE_LOADING":
            return {
                ...newstate,
                PayOutScheduleLoading: true,
                MultipleSupplier: false,
            };

        case "GET_PAY_OUT_SCHEDULE":
            return {
                ...newstate,
                PayOutScheduleData: action.value,
                MultipleSupplier: false,
                PayOutScheduleLoading: false,           
            };

        case "RESET_SUPPLIER_DATA_FOR_PAY_OUT_SCHEDULE":
            return {
                ...newstate,
                InvoiceCustomerDataOnly: "",
                InvoiceCustomerDataStatus: false,
            };

        case "GET_GRN_SUPPLIER_DATA_ONLY":
                return {
                    ...newstate,
                    GRNSupplierData: action.value,
                    GRNSupplierDataStatus: true,
                };

        case "GET_GRN_SUPPLIER_DATA_CHANGE_STATE":
            return {
                ...newstate,
                GRNSupplierDataStatus: false,
            };

        case "RESET_GET_SUPPLIER_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetSupplierRemainingPaymentData: null,
            };

        case "GET_SUPPLIER_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetSupplierRemainingPaymentData: action.value,
            };

        case "GET_CASH_ACCOUNT_DATA":
            return {
                ...newstate,
                getCashAccountData: action.value,
            };

        case "CHANGE_PAYMENT_LOADING":
            return {
                ...newstate,
                ChangePaymentStatusLoading: true,
            };

        case "CHANGE_ADD_PAYMENT_MODAL_STATE":
            return {
                ...newstate,
                ChangePaymentModalStatus: false,
                PaymentVoucherModalState: true,
            };

        case "CHANGE_ADD_MULTIPLE_PAYMENT_MODAL_STATE":
            return {
                ...newstate,
                ChangePaymentModalStatus: false,
                MultipleSupplier: false,
                GetSupplierRemainingPaymentData: null,
                PaymentVoucherModalState: true,
            };

        case "CHANGE_CLOSE_PAYMENT_MODAL_STATE":
            return {
                ...newstate,
                PaymentVoucherModalState: action.value,
            };

        case "ADD_SUPPLIER_PAYMENT_SUCCESS":
            return {
                ...newstate,
                ChangePaymentModalStatus: true,
                ChangePaymentStatusLoading: false,
                PaymentVoucherModalState: false,
                paymentVoucherDetails: action.value,
            };

        case "SWITCH_PAYOUT_SUPPLIER_MODE":
            return {
                ...newstate,
                MultipleSupplier: !action.value,
                GetSupplierRemainingPaymentData: null,
            };

        case "GET_SUPPLIER_RAW_MATERIAL_FOR_PRICE":
            return {
                ...newstate,
                SupplierRawMaterialForPrice: action.value,
                RawMaterialInspectionGRNDetails: [],
                RawMaterialInspections: [],
            };

        case "GET_RAW_MATERIAL_DEATAILS_FOR_PRICE":
            return {
                ...newstate,
                RawMaterialInspections: action.value.rawMaterialInspections,
                RawMaterialInspectionGRNDetails: action.value.rawMaterialInspectionGRNDetails,
                TotalPayQty: action.value.totalPayQty,
                TotalAmount: action.value.totalAmount
            };

        case "CHANGE_GRN_VALUES_SUCCESS":
            return {
                ...newstate,
                ChangePaymentModalStatus: true,
            };
 
        case "CLEAR_GRN_PRICE_DATA":
                return {
                    ...newstate,
                    RawMaterialInspections: [],
                    RawMaterialInspectionGRNDetails: [],
                    TotalPayQty: "",
                    TotalAmount: ""
                };
        
        case "GET_PAYMENT_VOUCHER_DATA":
            return {
                ...state,
                PaymentVoucherModalState: true,
                paymentVoucherDetails: action.value,
            };
            
        case "ADD_PO_ATTACHMENTS":
            return {
                ...state,
                GetAttachmentsData: action.value,
            };

        case "GET_PO_ATTACHMENTS":
            return {
                ...state,
                GetAttachmentsData: action.value,
            };

        case "PO_ATTACHMENT_DELETED":
            return {
                ...state,
                GetAttachmentsData: action.value,
            };

        case "PO_LOCAL_ATTACHMENT_DELETED":
     
            return {
                ...state,
                GetAttachmentsData: action.value,
            };

        case "GET_GRN_EXPORT_REPORT_DATA":
            return {
                ...newstate,
                grnExportReportData: action.value,
            };

        case "SWITCH_PAYOUT_INVOICE_MODE":
            return {
                ...newstate,
                MultipleInvoiceState: !action.value,  
            };

        case "GET_SETTLEMENT_RECONCILE_PAYMENTS":
            return {
                ...newstate,
                getSettlementReconcileData: action.value,
            };
                                       
        default:
            break;
    }

    return state;
};
export default reducer;
