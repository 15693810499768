const initialState = {
    Report1: [
        //{ "itemId": "1", "itemName": "Others 4", "unit": "kg", "unitPrice": "1100" },
        //{ "itemId": "2", "itemName": "Others 3", "unit": "kg", "unitPrice": "2100" },
        //{ "itemId": "3", "itemName": "Others 1", "unit": "kg", "unitPrice": "3100" }
    ],
    Report1Total: 0.00,
    Report1TotalOutstanding: 0.00,
    Report2: [
        //{ "itemId": "1", "itemName": "Others 4", "unit": "kg", "unitPrice": "1100" },
        //{ "itemId": "2", "itemName": "Others 3", "unit": "kg", "unitPrice": "2100" },
        //{ "itemId": "3", "itemName": "Others 1", "unit": "kg", "unitPrice": "3100" }
    ],
    Report2Total: 0.00,
    Report2QtyTotal: 0.00,
    Report2WeightTotal: 0.00,
    Report3:[],
    Report3Total: 0.00,
    LineValue: 0.00,
    CustomerPicker: [{"value": "1", "label": "Others 4"}, {"value": "2", "label": "1100"}],
    CustomerTypePicker: [{"value": "1", "label": "Others 4"}, {"value": "2", "label": "1100"}],
    InvoiceReportData: [],
    CustomerRegion: []
};

const reducer = (state = initialState, action) => {
    const newstate = {...state};

    switch (action.type) {
        case "INVREP_GET_REPORT1":
            //console.log("PO_GET_ALL_MATERIALS", action.value);           
            newstate.Report1 = action.value;

            let report1Total = 0;
            let report1TotalOutstanding = 0;
            action.value.map((item, i) => {
                report1Total = report1Total + item.totalValue;
                report1TotalOutstanding = report1TotalOutstanding + item.totalOutstandingValue;
                return true;
            });

            return {
                ...newstate,
                Report1: [...newstate.Report1],
                Report1Total: report1Total,
                Report1TotalOutstanding: report1TotalOutstanding
            };

        case "INVREP_GET_REPORT2":
            newstate.Report2 = action.value;
            let report2Total = 0;
            let report2QtyTotal = 0;
            let report2WeightTotal = 0;
            action.value.map((item, i) => {
                report2Total = report2Total + item.netValue;
                report2QtyTotal = report2QtyTotal + item.qty;
                report2WeightTotal = report2WeightTotal + item.weight;
                return true;
            });
            return {
                ...newstate,
                Report2: [...newstate.Report2],
                Report2Total: report2Total,
                Report2QtyTotal: report2QtyTotal,
                Report2WeightTotal: report2WeightTotal,
            };
            
         case "INVREP_GET_REPORT3":
            newstate.Report3 = action.value;
            let report3Total = 0;
            let lineValue = 0;
            let newReport3 = [];

            action.value.map((item, i) => {
                
                if(!newReport3.includes(item.invoiceNumber)){
                    report3Total = report3Total + item.totalValue;
                    newReport3.push(item.invoiceNumber);
                }
                lineValue = lineValue + item.lineValue;
                return true;
            });
            return {
                ...newstate,
                Report3: [...newstate.Report3],
                Report3Total: report3Total,
                LineValue: lineValue,
            };
        case "CUS_PICKER":
            newstate.CustomerPicker = action.value;
            console.log("CUS_PICKER", newstate.CustomerPicker);
            return {
                ...newstate,
                CustomerPicker: [...newstate.CustomerPicker],
            };

        case "CUS_TYPEPICKER":
            let cusTypeList = [];
            action.value.map((item, i) => {
                cusTypeList.push({
                    value: item.value,
                    label: item.label
                });
                return true;
            });

            newstate.CustomerTypePicker = cusTypeList;
            //console.log("CUS_TYPEPICKER", newstate.CustomerTypePicker);
            return {
                ...newstate,
                CustomerTypePicker: [...newstate.CustomerTypePicker],
            };

        case "GET_INVOICE_REPORT_DATA":
                newstate.InvoiceReportData = action.value;
                    console.log("InvoiceReportData", newstate.InvoiceReportData);
            return {
                ...newstate,
                InvoiceReportData: [...newstate.InvoiceReportData],
            };

        case "GET_Commutative_Item_Sales":
            newstate.CommutativeItemReport = action.value;
            let commutativeItemTotal = 0;
            let commutativeItemQtyTotal = 0;
            let commutativeItemWeightTotal = 0;
            action.value.map((item, i) => {
                commutativeItemTotal = commutativeItemTotal + item.netValue;
                commutativeItemQtyTotal = commutativeItemQtyTotal + item.qty;
                commutativeItemWeightTotal = commutativeItemWeightTotal + item.weight;
                return true;
            });
            return {
                ...newstate,
                CommutativeItemReport: [...newstate.CommutativeItemReport],
                CommutativeItemTotal: commutativeItemTotal,
                CommutativeItemQtyTotal: commutativeItemQtyTotal,
                CommutativeItemWeightTotal: commutativeItemWeightTotal,
            };

        case "GET_SALES_TAX_DATA":
            return {
                ...newstate,
                SalesTaxData: action.value,
            };

        case "GET_BANK_ACCOUNT_DATA":
            return {
                ...newstate,
                BankAccountData: action.value,
            };

        case "CUS_REGION_PICKER":
            return {
                ...newstate,
                CustomerRegion: action.value,
            };


        default:
            break;
    }

    return state;
};
export default reducer;
