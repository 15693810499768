const initialState = {
    PaymentHistoryData: [],
    PaymentVoucherModalState: false,
    PaymentHistoryDetailData: [],
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "GET_PAYMENT_HISTORY":
            newstate.PaymentHistoryData = action.value;
            return {
                ...newstate,
                PaymentHistoryData: [...newstate.PaymentHistoryData],
            };   
            
        case "GET_PAYMENT_DEATAIL_BY_BULK":
            newstate.PaymentHistoryDetailData = action.value;
            return {
                ...newstate,
                PaymentHistoryDetailData: [...newstate.PaymentHistoryDetailData],
            };   
                
        default:
            break;  
    }

    return state;
};
export default reducer;