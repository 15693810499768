const initialState = {
    CategoryAddStatus: false,
    CategoryAddLoadingStatus: false,
    CategoryUpdateStatus: false,
    CategoryUpdateLoadingStatus: false,
    ProductAddStatus: false,
    ProductAddLoadingStatus: false,
    ProductUpdateStatus: false,
    ProductUpdateLoading: false,
    ShelfLivesRegStatus: false,
    ShelfLivesUpdateStatus: false,
    DeliveryUpdateStatus: false,
    DeliveryUpdateLoading: false,
    DeliveryStatus: false,
    DeliveryRegLoading: false,
    ItemStatus: false,
    ItemRegLoading: false,
    ItemUpdateStatus: false,
    ItemUpdateLoading: false,
    ParaUpdateStatus: false,
    paramStatus: false,
    AddparamLoadingStatus: false,
    TaxStatus: false,
    AddTaxLoadingStatus: false,
    TaxUpdateStatus: false,
    TaxUpdateDataLoading: false,
    CurrencyStatus: false,
    AddCurrencyLoadingStatus: false,
    CountryStatus: false,
    AddCountryLoadingStatus: false,
    CurrencyUpdateStatus: false,
    CurrencyUpdateLoadingStatus: false,
    RegionStatus: false,
    AddRegionLoadingStatus: false,
    RegionUpdateStatus: false,
    RegionUpdateLoadingStatus: false,
    CustomerTypeStatus: false,
    AddCustomerTypeLoadingStatus: false,
    CustomerTypeUpdateStatus: false,
    CustomerTypeUpdateLoadingStatus: false,
    CustomerConDeptStatus: false,
    AddCustomerConDeptLoadingStatus: false,
    CustomerConDeptUpdateStatus: false,
    CustomerConDeptUpdateLoadingStatus: false,
    PaymentMethodUpdateStatus: false,
    PaymentMethodStatus: false,
    AddPaymentMethodLoadingStatus: false,
    InvoiceStatus: false,
    AddnvoiceLoadingStatus: false,
    InvoiceUpdateStatus: false,
    InvoiceTypeUpdateLoadingStatus: false,
    PaymentTermStatus: false,
    AddPaymentTermLoadingStatus: false,
    PaymentTermUpdateStatus: false,
    PaymentTermUpdateLoadingStatus: false,
    CategoryData: null,
    CategoryDataPageSize: 0,
    ParamData: null,
    ParamDataPageSize: 0,
    ParaLodingStatus: false,
    ProductData: null,
    ProductDataPageSize: 0,
    ShelfData: null,
    ShelfDataPageSize: 0,
    DeliveryData: null,
    DeliveryDataPageSize: 0,
    ItemData: null,
    DistributionItems: null,
    ItemDataPageSize: 0,
    ItemDataToUpdate: null,
    TaxData: null,
    CusTaxData: null,
    CountryData: null,
    CountryUpdateStatus: false,
    CountryUpdateLoadingStatus: false,
    CurrencyData: null,
    RegionData: null,
    CustomerTypeData: null,
    CustomerConDeptData: null,
    InvoiceData: null,
    PaymentMethodData: null,
    PaymentTermData: null,
    countrySearchData: null,
    companyData: null,
    updateCompanyDataStatus: false,
    addsurchange: false,
    CusSurData: null,
    invoicesurchangedata: null,
    CusAssignedSurData: null,
    InvoiceMethodUpdateStatus: false,
    UnitTypeData: null,
    addunittype: false,
    addmeasureunit: false,
    measureunitdata: null,
    addrowmaterialtype: false,
    getrowmaterialtype: null,
    updaterowmaterialtype: false,
    addRowMaterialdata: false,
    getrowmaterial: null,
    getDistributionItem: [],
    addWipdataSuccess: false,
    getWipData: null,
    addWipMaterialData: false,
    getWipMaterialData: null,
    filerMeasuereUnits: null,
    disableButtton: true,
    disableButttonItem: true,
    getItemMaterialdata: null,
    addItemMaterialList: false,
    getCompanyImagesData: null,
    getImageUrldata: null,
    getBarcode: null,
    changebarcodeStatus: false,
    loadinginvoicesurchangedata: false,
    invoiceSurchargeDataPageSize: null,
    loadingupdatesurchargeButton: false,
    UnitTypeDataPageSize: null,
    loadingUnitTypeButton: false,
    loadingunitofmeasure: false,
    loadingRawMaterialType: false,
    updateLoadingRowmaterialtype: false,
    RawMaterialDataPageSize: null,
    loadingRawMaterialData: false,
    updateRawMaterialData: false,
    changeaddWipdataSuccess: false,
    WipDataPageSize: null,
    loadingWipData: false,
    loadingUpdatedWipdata: false,
    getWipId: null,
    GetWipId2: null,
    getWipId2: null,
    WipMaterialDataPageSize: null,
    loadingAddItemMaterialList: false,
    updateItemMaterialList: false,
    loadingItemMaterialList: false,
    ItemMaterialDataPageSize: 0,
    loadingAddGrade: false,
    addGradeSuccess: false,
    getGradedata: null,
    gradeDataPageSize: 0,
    updateGradeDataSuccess: false,
    loadingUpdateGrade: false,
    updateunitTypeStatus: false,
    updatemeasuredatStatus: false,
    AddDocTypeLoadingStatus: false,
    DocTypeStatus: true,
    DocTypeData: null,
    DocTypeUpdateStatus: false,
    DocTypeUpdateLoadingStatus: false,
    ManualStepData: null,
    ManualStepCodes: null,
    ManualStepLoadingStatus: false,
    ManualStepAddStatus: false,
    ManualStepUpdateStatus: false,
    RawMqLoadingStatus: false,
    RawMqAddStatus: false,
    loadingUpdateRawMq: false,
    RawMqUpdateStatus: false,
    RawMqData: [],
    RawMIData: [],
    RawMILoadingStatus: false,
    RawMIAddStatus: false,
    loadingUpdateRawMI: false,
    RawMIUpdateStatus: false,
    getWeightData: null,
    WIPSTEPLoadingStatus: false,
    WIPStepAddStatus: false,
    loadingUpdateWIPStep: false,
    WIPStepUpdateStatus: false,
    wipStepData: null,
    loadingUpdateWIPStepSequence: false,
    WIPStepUpdateStatusSequence: false,
    loadingcompanydata: false,
    addingCompanydataStatus: false,
    ITEMStepData: null,
    ITEMSTEPLoadingStatus: false,
    ITEMStepAddStatus: false,
    loadingUpdateITEMStep: false,
    ITEMStepUpdateStatus: false,
    loadingUpdateITEMStepSequence: false,
    ITEMStepUpdateStatusSequence: false,
    loadingConfirmMeasureUnit: false,
    confirmChangedofMeasureUnit: false,
    loadingUpdateconfirmMeasure: false,
    UpdateconfirmMeasure: false,
    ItemWeightUnit: null,
    PurchaseSurchargeTypes: [],
    LoadingPurchaseSurchargeTypeData: "",
    LoadingUpdatePurchaseSurchargeButton: "",
    AddPurchaseSurchargeType: "",
    AssignedRecordTypeData: "",
    AssignedRecordTypeDataPageSize: "",
    ItemCusPriceItem: null,
    itemAssignModal: false,
    ItemCusPriceStatus: false,
    CusPricesUpdate: false,
    SurchargeData : null,
};

const reducer = (state = initialState, action) => {
    const newstate = { ...state };

    switch (action.type) {
        case "ADD_CATEGORY_LOADING":
            return {
                ...state,
                CategoryAddLoadingStatus: true,
            };
        case "ADD_CATEGORY_SUCCESS":
            return {
                ...state,
                CategoryAddStatus: true,
                CategoryAddLoadingStatus: false,
            };
        case "ADD_CATEGORY_FAIL":
            return {
                ...state,
                CategoryAddStatus: false,
                CategoryAddLoadingStatus: false,
            };
        case "CHANGE_ADD_CATEGORY_SUCCESS":
            return {
                ...state,
                CategoryAddStatus: false,
            };
        case "UPDATE_CATEGORY_LOADING":
            return {
                ...state,
                CategoryUpdateLoadingStatus: true,
            };
        case "UPDATE_CATEGORY_SUCCESS":
            return {
                ...state,
                CategoryUpdateStatus: true,
                CategoryUpdateLoadingStatus: false,
            };
        case "UPDATE_CATEGORY_DATA_UNSUCCESS":
            return {
                ...state,
                CategoryUpdateStatus: false,
                CategoryUpdateLoadingStatus: false,
            };
        case "CHANGE_UPDATE_CATEGORY_SUCCESS":
            return {
                ...state,
                CategoryUpdateStatus: false,
            };

        case "GET_CATEGORY_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    CategoryData: action.value.rows,
                    CategoryDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    CategoryData: action.value,
                };
            }
        case "CATEGORY_DELETED":
            return {
                ...state,
                CategoryData: state.CategoryData.filter((Category) => Category.id !== action.value),
            };
        case "ADD_PRODUCT_SUCCESS":
            return {
                ...state,
                ProductAddStatus: true,
                ProductAddLoadingStatus: false,
            };
        case "ADD_PRODUCT_LOADING":
            return {
                ...state,
                ProductAddLoadingStatus: true,
            };
        case "CHANGE_PRODUCT_SUCCESS_STATE":
            return {
                ...state,
                ProductAddStatus: false,
            };
        case "ADD_PRODUCT_FAIL":
            return {
                ...state,
                ProductAddStatus: false,
                ProductAddLoadingStatus: false,
            };

        case "UPDATE_PRODUCT_SUCCESS":
            return {
                ...state,
                ProductUpdateStatus: true,
                ProductUpdateLoading: false,
            };
        case "UPDATE_PRODUCT_LOADING":
            return {
                ...state,
                ProductUpdateLoading: true,
            };
        case "UPDATE_PRODUCT_DATA_UNSUCCESS":
            return {
                ...state,
                ProductUpdateStatus: false,
                ProductUpdateLoading: false,
            };
        case "CHANGE_PRODUCT_UPDATE_STATE":
            return {
                ...state,
                ProductUpdateStatus: false,
            };
        case "GET_PRODUCT_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    ProductData: action.value.rows,
                    ProductDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    ProductData: action.value,
                };
            }

        case "GET_Items_By_Product":
            return {
                ...state,
                ItemData: action.value,
            };

        case "PRODUCT_DELETED":
            return {
                ...state,
                ProductData: state.ProductData.filter((Product) => Product.id !== action.value),
            };
        case "ADD_SHELF_SUCCESS":
            return {
                ...state,
                ShelfLivesRegStatus: true,
            };
        case "CHANGE_ADD_SHELF_SUCCESS":
            return {
                ...state,
                ShelfLivesRegStatus: false,
            };
        case "ADD_SHELF_FAIL":
            return {
                ...state,
                ShelfLivesRegStatus: false,
            };
        case "UPDATE_SHELF_SUCCESS":
            return {
                ...state,
                ShelfLivesUpdateStatus: true,
            };
        case "CHANGE_UPDATE_SHELF_SUCCESS":
            return {
                ...state,
                ShelfLivesUpdateStatus: false,
            };
        case "UPDATE_SHELF_DATA_UNSUCCESS":
            return {
                ...state,
                ShelfLivesUpdateStatus: false,
            };
        case "GET_SHELF_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    ShelfData: action.value.rows,
                    ShelfDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    ShelfData: action.value,
                };
            }

        case "SHELF_DELETED":
            return {
                ...state,
                ShelfData: state.ShelfData.filter((Shelf) => Shelf.id !== action.value),
            };
        case "ADD_DELIVERY_LOADING":
            return {
                ...state,
                DeliveryStatus: true,
            };

        case "ADD_DELIVERY_TYPE_SUCCESS":
            return {
                ...state,
                DeliveryStatus: true,
                DeliveryRegLoading: false,
            };
        case "ADD_DELIVERY_TYPE_FAIL":
            return {
                ...state,
                DeliveryStatus: false,
                DeliveryRegLoading: false,
            };
        case "CHANGE_DELIVERY_STATE":
            return {
                ...state,
                DeliveryStatus: false,
            };
        case "GET_DELIVERY_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    DeliveryData: action.value.rows,
                    DeliveryDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    DeliveryData: action.value,
                };
            }

        case "DELIVERY_DELETED":
            return {
                ...state,
                DeliveryData: state.DeliveryData.filter((DeliveryData) => DeliveryData.id !== action.value),
            };
        case "UPDATE_DELIVERY_LOADING":
            return {
                ...state,
                DeliveryUpdateLoading: true,
            };

        case "DELIVERY_DATA_UPDATED":
            return {
                ...state,
                DeliveryUpdateStatus: true,
                DeliveryUpdateLoading: false,
            };
        case "UPDATE_DELIVERY_DATA_UNSUCCESS":
            return {
                ...state,
                DeliveryUpdateStatus: false,
                DeliveryUpdateLoading: false,
            };
        case "CHANGE_UPDATE_DELIVERY_STATE":
            return {
                ...state,
                DeliveryUpdateStatus: false,
            };

        case "ADD_ITEM_SUCCESS":
            return {
                ...state,
                ItemStatus: true,
                ItemRegLoading: false,
            };

        case "ADD_ITEM_LOADING":
            return {
                ...state,
                ItemRegLoading: true,
            };
        case "ADD_ITEM_FAIL":
            return {
                ...state,
                ItemStatus: false,
                ItemRegLoading: false,
            };
        case "CHANGE_ITEM_STATE":
            return {
                ...state,
                ItemStatus: false,
            };

        case "GET_ITEM_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    ItemData: action.value.rows,
                    ItemDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    ItemData: action.value,
                };
            }

        case "GET_ITEM_DISTRIBUTION_ITEM_DATA":
            return {
                ...state,
                DistributionItems: action.value,
            };

        case "ITEM_UPDATED":
            return {
                ...state,
                ItemUpdateStatus: true,
                ItemUpdateLoading: false,
            };
        case "ITEM_UPDATE_LOADING":
            return {
                ...state,
                ItemUpdateLoading: true,
            };
        case "ITEM_DATA_TO_UPDATE":
            return {
                ...state,
                ItemDataToUpdate: action.value,
            };
        case "CHANGE_ITEM_UPDATE_STATE":
            return {
                ...state,
                ItemUpdateStatus: false,
            };
        case "UPDATE_ITEM_UNSUCCESS":
            return {
                ...state,
                ItemUpdateStatus: false,
                ItemUpdateLoading: false,
            };

        case "ITEM_DELETED":
            return {
                ...state,
                ItemData: state.ItemData.filter((item) => item.id !== action.value),
            };
        case "ADD_PARAMETER_SUCCESS":
            return {
                ...state,
                paramStatus: true,
                AddparamLoadingStatus: false,
            };
        case "ADD_PARAMETER_LOADING":
            return {
                ...state,
                AddparamLoadingStatus: true,
            };
        case "ADD_PARAMETER_FAIL":
            return {
                ...state,
                paramStatus: false,
                AddparamLoadingStatus: false,
            };
        case "CHANGE_PARAMETER_STATE":
            return {
                ...state,
                paramStatus: false,
            };

        case "GET_PARAMETER_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    ParamData: action.value.rows,
                    ParamDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    ParamData: action.value,
                };
            }
        case "CHANGE_PARAMETER_UPDATE_STATE":
            return {
                ...state,
                ParaUpdateStatus: false,
            };

        case "UPDATE_PARAMETER_LOADING":
            return {
                ...state,
                ParaLodingStatus: false,
            };
        case "PARAMETER_UPDATED":
            return {
                ...state,
                ParaUpdateStatus: true,
                ParaLodingStatus: false,
            };

        case "UPDATE_PARAMETER_UNSUCCESS":
            return {
                ...state,
                ParaUpdateStatus: false,
                ParaLodingStatus: false,
            };

        case "PARA_DELETED":
            return {
                ...state,
                ParamData: state.ParamData.filter((para) => para.id !== action.value),
            };

        case "ADD_TAX_TYPE_SUCCESS":
            return {
                ...state,
                TaxStatus: true,
                AddTaxLoadingStatus: false,
            };
        case "ADD_TAX_LOADING":
            return {
                ...state,
                AddTaxLoadingStatus: true,
            };
        case "ADD_TAX_TYPE_FAIL":
            return {
                ...state,
                TaxStatus: false,
                AddTaxLoadingStatus: false,
            };
        case "CHANGE_TAX_STATE":
            return {
                ...state,
                TaxStatus: false,
            };

        case "GET_TAX_DATA":
            return {
                ...state,
                TaxData: action.value,
            };
        case "GET_CUS_TAX_DATA":
            return {
                ...state,
                CusTaxData: action.value,
            };
        case "CHANGE_TAX_UPDATE_STATE":
            return {
                ...state,
                TaxUpdateStatus: false,
            };
        case "TAX_UPDATED":
            return {
                ...state,
                TaxUpdateStatus: true,
                TaxUpdateDataLoading: false,
            };
        case "UPDATE_VATDATA_LOADING":
            return {
                ...state,
                TaxUpdateDataLoading: true,
            };

        case "UPDATE_TAX_UNSUCCESS":
            return {
                ...state,
                TaxUpdateStatus: false,
                TaxUpdateDataLoading: false,
            };

        case "TAX_DELETED":
            return {
                ...state,
                TaxData: state.TaxData.filter((tax) => tax.id !== action.value),
            };

        case "ADD_COUNTRY_SUCCESS":
            return {
                ...state,
                AddCountryLoadingStatus: false,
                CountryStatus: true,
            };
        case "ADD_COUNTRY_LOADING":
            return {
                ...state,
                AddCountryLoadingStatus: true,
            };
        case "ADD_COUNTRY_FAIL":
            return {
                ...state,
                CountryStatus: false,
                AddCountryLoadingStatus: false,
            };
        case "CHANGE_COUNTRY_STATE":
            return {
                ...state,
                CountryStatus: false,
            };

        case "GET_COUNTRY_DATA":
            return {
                ...state,
                CountryData: action.value,
            };
        case "CHANGE_COUNTRY_UPDATE_STATE":
            return {
                ...state,
                CountryUpdateStatus: false,
            };
        case "COUNTRY_UPDATED":
            return {
                ...state,
                CountryUpdateStatus: true,
                CountryUpdateLoadingStatus: false,
            };
        case "UPDATE_COUNTRY_LOADING":
            return {
                ...state,
                CountryUpdateLoadingStatus: true,
            };
        case "UPDATE_COUNTRY_UNSUCCESS":
            return {
                ...state,
                CountryUpdateStatus: false,
                CountryUpdateLoadingStatus: false,
            };

        case "COUNTRY_DELETED":
            return {
                ...state,
                CountryData: state.CountryData.filter((Country) => Country.id !== action.value),
            };

        case "ADD_CURRENCY_SUCCESS":
            return {
                ...state,
                CurrencyStatus: true,
                AddCurrencyLoadingStatus: false,
            };
        case "ADD_CURRENCY_LOADING":
            return {
                ...state,
                AddCurrencyLoadingStatus: true,
            };
        case "ADD_CURRENCY_FAIL":
            return {
                ...state,
                CurrencyStatus: false,
                AddCurrencyLoadingStatus: false,
            };
        case "CHANGE_CURRENCY_STATE":
            return {
                ...state,
                CurrencyStatus: false,
            };

        case "GET_CURRENCY_DATA":
            return {
                ...state,
                CurrencyData: action.value,
            };
        case "CHANGE_CURRENCY_UPDATE_STATE":
            return {
                ...state,
                CurrencyUpdateStatus: false,
            };
        case "CURRENCY_UPDATED":
            return {
                ...state,
                CurrencyUpdateStatus: true,
                CurrencyUpdateLoadingStatus: false,
            };
        case "UPDATE_CURRENCY_LOADING":
            return {
                ...state,
                CurrencyUpdateLoadingStatus: true,
            };
        case "UPDATE_CURRENCY_UNSUCCESS":
            return {
                ...state,
                CurrencyUpdateStatus: false,
                CurrencyUpdateLoadingStatus: false,
            };

        case "CURRENCY_DELETED":
            return {
                ...state,
                CurrencyData: state.CurrencyData.filter((Currency) => Currency.id !== action.value),
            };

        case "ADD_REGION_SUCCESS":
            return {
                ...state,
                RegionStatus: true,
                AddRegionLoadingStatus: false,
            };
        case "ADD_REGION_LOADING":
            return {
                ...state,
                AddRegionLoadingStatus: true,
            };
        case "ADD_REGION_FAIL":
            return {
                ...state,
                RegionStatus: false,
                AddRegionLoadingStatus: false,
            };
        case "CHANGE_REGION_STATE":
            return {
                ...state,
                RegionStatus: false,
            };

        case "GET_REGION_DATA":
            return {
                ...state,
                RegionData: action.value,
            };
        case "CHANGE_REGION_UPDATE_STATE":
            return {
                ...state,
                RegionUpdateStatus: false,
            };
        case "REGION_UPDATED":
            return {
                ...state,
                RegionUpdateStatus: true,
                RegionUpdateLoadingStatus: false,
            };
        case "UPDATE_REGION_LOADING":
            return {
                ...state,
                RegionUpdateLoadingStatus: true,
            };
        case "UPDATE_REGION_UNSUCCESS":
            return {
                ...state,
                RegionUpdateStatus: false,
                RegionUpdateLoadingStatus: false,
            };

        case "REGION_DELETED":
            return {
                ...state,
                RegionData: state.RegionData.filter((Region) => Region.id !== action.value),
            };
        case "ADD_CUSTOMER_TYPE_SUCCESS":
            return {
                ...state,
                CustomerTypeStatus: true,
                AddCustomerTypeLoadingStatus: false,
            };
        case "ADD_CUSTYPE_DATA_LOADING":
            return {
                ...state,
                AddCustomerTypeLoadingStatus: true,
            };
        case "ADD_CUSTOMER_TYPE_FAIL":
            return {
                ...state,
                CustomerTypeStatus: false,
                AddCustomerTypeLoadingStatus: false,
            };
        case "CHANGE_CUSTOMER_TYPE_STATE":
            return {
                ...state,
                CustomerTypeStatus: false,
            };

        case "GET_CUSTOMER_TYPE_DATA":
            return {
                ...state,
                CustomerTypeData: action.value,
            };
        case "CHANGE_CUSTOMER_TYPE_UPDATE_STATE":
            return {
                ...state,
                CustomerTypeUpdateStatus: false,
            };
        case "CUSTOMER_TYPE_UPDATED":
            return {
                ...state,
                CustomerTypeUpdateStatus: true,
                CustomerTypeUpdateLoadingStatus: false,
            };
        case "UPDATE_CUS_TYPE_DATA_LOADING":
            return {
                ...state,
                CustomerTypeUpdateLoadingStatus: true,
            };
        case "UPDATE_CUSTOMER_TYPE_UNSUCCESS":
            return {
                ...state,
                CustomerTypeUpdateStatus: false,
                CustomerTypeUpdateLoadingStatus: false,
            };

        case "CUSTOMER_TYPE_DELETED":
            return {
                ...state,
                CustomerTypeData: state.CustomerTypeData.filter((CustomerType) => CustomerType.id !== action.value),
            };

        case "ADD_CUSTOMER_CONTACT_DEPARTMENT_SUCCESS":
            return {
                ...state,
                CustomerConDeptStatus: true,
                AddCustomerConDeptLoadingStatus: false,
            };
        case "ADD_CUS_CON_DEPT_LOADING":
            return {
                ...state,
                AddCustomerConDeptLoadingStatus: true,
            };
        case "ADD_CUSTOMER_CONTACT_DEPARTMENT_FAIL":
            return {
                ...state,
                CustomerConDeptStatus: false,
                AddCustomerConDeptLoadingStatus: false,
            };
        case "CHANGE_CUSTOMER_CONTACT_DEPARTMENT_STATE":
            return {
                ...state,
                CustomerConDeptStatus: false,
            };

        case "GET_CUSTOMER_CONTACT_DEPARTMENT_DATA":
            return {
                ...state,
                CustomerConDeptData: action.value,
            };
        case "CHANGE_CUSTOMER_CONTACT_DEPARTMENT_UPDATE_STATE":
            return {
                ...state,
                CustomerConDeptUpdateStatus: false,
            };
        case "CUSTOMER_CONTACT_DEPARTMENT_UPDATED":
            return {
                ...state,
                CustomerConDeptUpdateStatus: true,
                CustomerConDeptUpdateLoadingStatus: false,
            };

        case "UPDATE_CUS_CON_DEPT_LOADING":
            return {
                ...state,
                CustomerConDeptUpdateLoadingStatus: true,
            };
        case "UPDATE_CUSTOMER_CONTACT_DEPARTMENT_UNSUCCESS":
            return {
                ...state,
                CustomerConDeptUpdateStatus: false,
                CustomerConDeptUpdateLoadingStatus: false,
            };

        case "CUSTOMER_CONTACT_DEPARTMENT_DELETED":
            return {
                ...state,
                CustomerConDeptData: state.CustomerConDeptData.filter((CustomerConDept) => CustomerConDept.id !== action.value),
            };
        case "ADD_INVOICE_TYPE_SUCCESS":
            return {
                ...state,
                InvoiceStatus: true,
                AddnvoiceLoadingStatus: false,
            };
        case "ADD_INVOICE_TYPE_LOADING":
            return {
                ...state,
                AddnvoiceLoadingStatus: true,
            };
        case "ADD_INVOICE_TYPE_FAIL":
            return {
                ...state,
                InvoiceStatus: false,
                AddnvoiceLoadingStatus: false,
            };
        case "CHANGE_INVOICE_TYPE_STATE":
            return {
                ...state,
                InvoiceStatus: false,
            };

        case "GET_INVOICE_DATA":
            return {
                ...state,
                InvoiceData: action.value,
            };
        case "CHANGE_INVOICE_TYPE_UPDATE_STATE":
            return {
                ...state,
                InvoiceUpdateStatus: false,
            };
        case "INVOICE_TYPE_UPDATED":
            return {
                ...state,
                InvoiceUpdateStatus: true,
                InvoiceTypeUpdateLoadingStatus: false,
            };
        case "UPDATE_INVOICE_TYPE_LOADING":
            return {
                ...state,
                InvoiceTypeUpdateLoadingStatus: true,
            };
        case "UPDATE_INVOICE_TYPE_UNSUCCESS":
            return {
                ...state,
                InvoiceUpdateStatus: false,
                InvoiceTypeUpdateLoadingStatus: false,
            };

        case "INVOICE_DELETED":
            return {
                ...state,
                InvoiceData: state.InvoiceData.filter((Invoice) => Invoice.id !== action.value),
            };

        case "ADD_PAYMENT_METHOD_SUCCESS":
            return {
                ...state,
                PaymentMethodStatus: true,
                AddPaymentMethodLoadingStatus: false,
            };
        case "ADD_PAY_METHOD_LOADING":
            return {
                ...state,
                AddPaymentMethodLoadingStatus: true,
            };
        case "ADD_PAYMENT_METHOD_FAIL":
            return {
                ...state,
                PaymentMethodStatus: false,
                AddPaymentMethodLoadingStatus: false,
            };
        case "CHANGE_PAYMENT_METHOD_STATE":
            return {
                ...state,
                PaymentMethodStatus: false,
            };

        case "GET_PAYMENT_DATA":
            return {
                ...state,
                PaymentMethodData: action.value,
            };
        case "CHANGE_PAYMENT__METHOD_UPDATE_STATE":
            return {
                ...state,
                PaymentMethodUpdateStatus: false,
            };
        case "PAYMENT_METHOD_UPDATED":
            return {
                ...state,
                PaymentMethodUpdateStatus: true,
                PaymentMethodUpdateLoadingStatus: false,
            };
        case "ADD_PAYMENT_UPDATED_LOADING":
            return {
                ...state,
                PaymentMethodUpdateLoadingStatus: true,
            };
        case "UPDATE_PAYMENT_METHOD_UNSUCCESS":
            return {
                ...state,
                PaymentMethodUpdateStatus: false,
                PaymentMethodUpdateLoadingStatus: false,
            };

        case "PAYMENT_DELETED":
            return {
                ...state,
                PaymentMethodData: state.PaymentMethodData.filter((pMethod) => pMethod.id !== action.value),
            };

        case "ADD_PAYMENT_TERM_SUCCESS":
            return {
                ...state,
                PaymentTermStatus: true,
                AddPaymentTermLoadingStatus: false,
            };
        case "ADD_PAYMENT_TERM_LOADING":
            return {
                ...state,
                AddPaymentTermLoadingStatus: true,
            };
        case "ADD_PAYMENT_TERM_FAIL":
            return {
                ...state,
                PaymentTermStatus: false,
                AddPaymentTermLoadingStatus: false,
            };
        case "CHANGE_PAYMENT_TERM_STATE":
            return {
                ...state,
                PaymentTermStatus: false,
            };

        case "GET_PAYMENT_TERM_DATA":
            return {
                ...state,
                PaymentTermData: action.value,
            };
        case "CHANGE_PAYMENT_TERM_UPDATE_STATE":
            return {
                ...state,
                PaymentTermUpdateStatus: false,
            };
        case "PAYMENT_TERM_UPDATED":
            return {
                ...state,
                PaymentTermUpdateStatus: true,
                PaymentTermUpdateLoadingStatus: false,
            };
        case "UPDATE_TERM_LOADING":
            return {
                ...state,
                PaymentTermUpdateLoadingStatus: true,
            };
        case "UPDATE_PAYMENT_TERM_UNSUCCESS":
            return {
                ...state,
                PaymentTermUpdateStatus: false,
                PaymentTermUpdateLoadingStatus: false,
            };

        case "PAYMENT_TERM_DELETED":
            return {
                ...state,
                PaymentTermData: state.PaymentTermData.filter((pTerm) => pTerm.id !== action.value),
            };
        case "GET_COUNTRY_SEARCHED_DATA":
            return {
                ...state,
                countrySearchData: action.value,
            };

        case "LOADING_COMPANY_DATA":
            return {
                ...state,
                loadingcompanydata: true,
            };

        case "ADD_COMPANY_DATA_SUCCEESS":
            return {
                ...state,
                loadingcompanydata: false,
                addingCompanydataStatus: true,
            };
        case "ADD_COMPANY_DATA_SUCCEESS_STATUS":
            return {
                ...state,
                addingCompanydataStatus: false,
            };

        case "ADD_COMPANY_DATA_FAIL":
            return {
                ...state,
                loadingcompanydata: false,
                addingCompanydataStatus: false,
            };

        case "COMPANY_DATA_UPDATED":
            return {
                ...state,
                loadingcompanydata: false,
                updateCompanyDataStatus: true,
            };

        case "UPDATE_COMPANY_DATA_UNSUCCESS":
            return {
                ...state,
                loadingcompanydata: false,
                updateCompanyDataStatus: false,
            };
        case "CHANGED_COMPANY_DATA_UPDATED":
            return {
                ...state,
                updateCompanyDataStatus: false,
            };
        case "ADD_INVOICESURCHARGE_SUCCESS":
            return {
                ...state,
                addsurchange: true,
                loadinginvoicesurchangedata: false,
            };

        case "ADD_INVOICESURCHARGE_FAIL":
            return {
                ...state,
                addsurchange: false,
                loadinginvoicesurchangedata: false,
            };
        case "CHANGE_ADD_INVOICESURCHARGE_SUCCESS":
            return {
                ...state,
                addsurchange: false,
            };

        case "GET_INVOICESURCHARGE_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    invoicesurchangedata: action.value.rows,
                    invoiceSurchargeDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    invoicesurchangedata: action.value,
                };
            }

        // return {
        //   ...state,
        //   invoicesurchangedata: action.value
        // };
        case "LOADING_SURCHARGE_TYPE_DATA":
            return {
                ...state,
                loadinginvoicesurchangedata: true,
            };

        case "INVOICESURCHARGE_DATA_UPDATED":
            return {
                ...state,
                InvoiceMethodUpdateStatus: true,
                loadingupdatesurchargeButton: false,
            };

        case "LOADING_UPDATE_SURCHARGE_BUTTON":
            return {
                ...state,
                loadingupdatesurchargeButton: true,
            };
        case "INVOICESURCHARGE_DATA_UNSUCCESS":
            return {
                ...state,
                InvoiceMethodUpdateStatus: false,
                loadingupdatesurchargeButton: false,
            };
        case "GET_CUS_SUR_DATA_ONLY":
            return {
                ...state,
                CusSurData: action.value,
            };

        case "GET_PURCHASE_SURCHARGE_TYPE_DATA":
            return {
                ...state,
                PurchaseSurchargeData: action.value,
            };

        case "GET_CUS_ASSIGNED_SUR_DATA":
            return {
                ...state,
                CusAssignedSurData: action.value,
            };
        case "GET_MATERIAL_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    UnitTypeData: action.value.rows,
                    UnitTypeDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    UnitTypeData: action.value,
                };
            }

        case "ADD_UNITTYPE_SUCCESS":
            return {
                ...state,
                addunittype: true,
                loadingUnitTypeButton: false,
            };

        case "ADD_UNITTYPE_UNSUCCESS":
            return {
                ...state,
                addunittype: false,
                loadingUnitTypeButton: false,
            };

        case "UNITTYPE_DATA_UPDATED":
            return {
                ...state,
                updateunitTypeStatus: true,
                loadingUnitTypeButton: false,
            };

        case "LOADING_UNITTYPE_DATA":
            return {
                ...state,
                loadingUnitTypeButton: true,
            };
        case "UPDATE_UNITTYPE_DATA_UNSUCCESS":
            return {
                ...state,
                updateunitTypeStatus: false,
                loadingUnitTypeButton: false,
            };
        case "ADD_UNITTYPE_SUCCESS_STATUS_CHANGE":
            return {
                ...state,
                addunittype: false,
            };
        case "LOADING_UNIT_TYPE_BUTTTON":
            return {
                ...state,
                loadingUnitTypeButton: true,
            };
        case "ADD_MEASUREUNIT_SUCCESS":
            return {
                ...state,
                addmeasureunit: true,
                loadingunitofmeasure: false,
            };

        case "LOADING_UNIT_OF_MEASURE":
            return {
                ...state,
                loadingunitofmeasure: true,
            };

        case "UNITTYPE_MEASURE_UNIT_DATA_UPDATED":
            return {
                ...state,
                updatemeasuredatStatus: true,
                updateLoadingmeasuredata: false,
            };

        case "UNITTYPE_MEASURE_UNIT_DATA_UPDATED_CHANGE":
            return {
                ...state,
                updatemeasuredatStatus: false,
            };
        case "UNITTYPE_MEASURE_UNIT_DATA_UNSUCCESS":
            return {
                ...state,
                updatemeasuredatStatus: false,
                updateLoadingmeasuredata: false,
            };

        case "LOADING_UPDATE_UNIT_OF_MEASURE":
            return {
                ...state,
                updateLoadingmeasuredata: true,
            };
        case "ADD_MEASUREUNIT_SUCCESS_STATUS_CHANGED":
            return {
                ...state,
                addmeasureunit: false,
            };
        case "ADD_MEASUREUNIT_UNSUCCESS":
            return {
                ...state,
                addmeasureunit: false,
                loadingunitofmeasure: false,
            };

        case "GET_MEASURE_UNIT_DATA":
            // return {
            //   ...state,
            //   measureunitdata: action.value
            // };
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    measureunitdata: action.value.rows,
                    MeasureDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    measureunitdata: action.value,
                };
            }
        case "ADD_ROW_MATERIAL_TYPE_SUCCESS":
            return {
                ...state,
                addrowmaterialtype: true,
                loadingRawMaterialType: false,
            };

        case "LOADING_RAW_MATERIAL_TYPE_DATA":
            return {
                ...state,
                loadingRawMaterialType: true,
            };

        case "CHANGE_STATUS_OF_ADD_ROW_MATERIAL_TYPE_SUCCESS":
            return {
                ...state,
                addrowmaterialtype: false,
            };

        case "ADD_ROW_MATERIAL_TYPE_UNSUCCESS":
            return {
                ...state,
                addrowmaterialtype: false,
                loadingRawMaterialType: false,
            };

        case "GET_ROW_MATERIAL_TYPE_SUCCESS":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getrowmaterialtype: action.value.rows,
                    rawMaterialTypeDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getrowmaterialtype: action.value,
                };
            }
        case "ROW_MATERIAL_TYPE_UPDATED":
            return {
                ...state,
                updaterowmaterialtype: true,
                updateLoadingRowmaterialtype: false,
            };

        case "LOADING_UPDATED_RAW_MATERIAL_TYPE":
            return {
                ...state,
                updateLoadingRowmaterialtype: true,
            };
        case "UPDATE_ROW_MATERIAL_TYPE_UNSUCCESS":
            return {
                ...state,
                updaterowmaterialtype: false,
                updateLoadingRowmaterialtype: false,
            };

        case "ADD_ROW_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                addRowMaterialdata: true,
                loadingRawMaterialData: false,
            };

        case "LOADING_ROW_MATERIAL":
            return {
                ...state,
                addRowMaterialdata: true,
                loadingRawMaterialData: true,
            };

        case "CHANGE_ADD_ROW_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                addRowMaterialdata: false,
            };
        case "ADD_ROW_MATERIAL_DATA_UNSUCCESS":
            return {
                ...state,
                addRowMaterialdata: false,
                loadingRawMaterialData: false,
            };

        case "LOADING_CONFIRM_MEASUREUNIT_SUCCESS":
            return {
                ...state,
                loadingConfirmMeasureUnit: true,
            };
        case "CONFIRM_MEASUREUNIT_SUCCESS":
            return {
                ...state,
                loadingConfirmMeasureUnit: false,
                confirmChangedofMeasureUnit: true,
            };
        case "CONFIRM_MEASUREUNIT_SUCCESS_CHANGED":
            return {
                ...state,
                confirmChangedofMeasureUnit: false,
            };
        case "CONFIRM_MEASUREUNIT_UNSUCCESS":
            return {
                ...state,
                loadingConfirmMeasureUnit: false,
                confirmChangedofMeasureUnit: false,
            };
        case "LOADING_CHANGE_UPDATE_MATERIAL":
            return {
                ...state,
                loadingUpdatedrawMaterials: true,
            };
        case "ROW_MATERIAL_DATA_UPDATED":
            return {
                ...state,
                updateRawMaterialData: true,
                loadingUpdatedrawMaterials: false,
            };
        case "CHANGE_ROW_MATERIAL_DATA_UPDATED_STATUS":
            return {
                ...state,
                updateRawMaterialData: false,
            };
        case "ROW_MATERIAL_DATA_UPDATED_UNSUCCESS":
            return {
                ...state,
                updateRawMaterialData: false,
                loadingUpdatedrawMaterials: false,
            };

        case "LOADING_CONFIRM_UPDATE_MEASUREUNIT_SUCCESS":
            return {
                ...state,
                loadingUpdateconfirmMeasure: true,
            };
        case "CONFIRM_UPDATE_MEASUREUNIT_SUCCESS":
            return {
                ...state,
                loadingUpdateconfirmMeasure: false,
                UpdateconfirmMeasure: true,
            };
        case "CONFIRM_UPDATE_MEASUREUNIT_SUCCESS_CHANGED":
            return {
                ...state,
                UpdateconfirmMeasure: false,
            };
        case "CONFIRM_UPDATE_MEASUREUNIT_UNSUCCESS":
            return {
                ...state,
                loadingUpdateconfirmMeasure: false,
                UpdateconfirmMeasure: false,
            };
        case "GET_ROW_MATERIAL_SUCCESS":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getrowmaterial: action.value.rows,
                    RawMaterialDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getrowmaterial: action.value,
                };
            }

        case "GET_DISTRIBUTION_ITEM_SUCCESS":
            return {
                ...state,
                getDistributionItem: action.value,
            };

        case "ADD_WIP_DATA_SUCCESS":
            return {
                ...state,

                addWipdataSuccess: true,
                loadingWipData: false,
            };
        case "GET_WIP_ID":
            return {
                ...state,
                getWipId: action.value,
            };

        case "GET_WIP_ID2":
            return {
                ...state,
                getWipId2: action.value,
            };
        case "LOADING_ADD_WIP_DATA":
            return {
                ...state,
                loadingWipData: true,
            };
        case "CHANGE_ADD_WIP_DATA_SUCCESS":
            return {
                ...state,
                addWipdataSuccess: false,
            };

        case "ADD_WIP_DATA_UNSUCCESS":
            return {
                ...state,
                addWipdataSuccess: false,
                loadingWipData: false,
            };
        case "WIP_DATA_UPDATED":
            return {
                ...state,
                loadingUpdatedWipdata: false,
            };
        case "WIP_DATA_UPDATED_UNSUCCESS":
            return {
                ...state,
                loadingUpdatedWipdata: false,
            };
        case "LOADING_WIP_UPDATE_DATA":
            return {
                ...state,
                loadingUpdatedWipdata: true,
            };
        case "GET_WIP_DATA_SUCCESS":
            // return {
            //   ...state,
            //   getWipData: action.value
            // };
            if (action.value.rows !== undefined) {
                return {
                    ...state,

                    getWipData: action.value.rows,
                    WipDataPageSize: action.value.pages,
                    // GetWipData :initialState.getWipId,
                };
            } else {
                return {
                    ...state,
                    getWipData: action.value,
                    //  GetWipData : initialState.getWipId,
                };
            }
        case "ADD_WIP_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                addWipMaterialData: true,
            };
        case "CHANGE_ADD_WIP_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                addWipMaterialData: false,
            };
        case "ADD_WIP_MATERIAL_DATA_UNSUCCESS":
            return {
                ...state,
                addWipMaterialData: false,
            };
        case "RESET_MATERIAL_LIST":
            return {
                ...state,
                getWipMaterialData: null,
            };
        case "GET_WIP_MATERIAL_DATA_SUCCESS":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getWipMaterialData: action.value.rows,
                    WipMaterialDataPageSize: action.value.pages,
                    GetWipData: newstate.getWipId,
                    GetWipId2: newstate.getWipId2,
                };
            } else {
                return {
                    ...state,
                    getWipMaterialData: action.value,
                    // GetWipData: newstate.getWipId,
                    // GetWipId2: newstate.getWipId2
                };
            }

        case "Change_disable_type":
            return {
                ...state,
                disableButtton: action.value,
            };
        case "ADD_ITEM_MATERIAL_DATA":
            return {
                ...state,
                addItemMaterialList: true,
                loadingAddItemMaterialList: false,
            };

        case "LOADING_ADD_ITEM_MATERIAL_DATA":
            return {
                ...state,
                loadingAddItemMaterialList: true,
            };
        case "ADD_ITEM_MATERIAL_DATA_FAILED":
            return {
                ...state,
                addItemMaterialList: false,
                loadingAddItemMaterialList: false,
            };

        case "CHANGE_ADD_ITEM_MATERIAL_DATA_STATUS":
            return {
                ...state,
                addItemMaterialList: false,
            };
        case "GET_ITEM_MATERIAL_DATA_SUCCESS":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getItemMaterialdata: action.value.rows,
                    ItemMaterialDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getItemMaterialdata: action.value,
                };
            }

        case "UPDATE_ITEM_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                updateItemMaterialList: true,
                loadingItemMaterialList: false,
            };

        case "UPDATE_ITEM_MATERIAL_DATA_UNSUCCESS":
            return {
                ...state,
                updateItemMaterialList: false,
                loadingItemMaterialList: false,
            };

        case "LOADING_UPDATE_ITEM_MATERIAL_DATA_SUCCESS":
            return {
                ...state,
                loadingItemMaterialList: true,
            };
        case "GET_COMPANY_DATA":
            return {
                ...state,
                companyData: action.value,
            };
        case "COMPANY_IMAGES_DETAILS":
            return {
                ...state,
                getCompanyImagesData: action.value,
            };

        case "COMPANY_IMAGE_URL_DATA":
            return {
                ...state,
                getImageUrldata: action.value,
            };

        case "GET_BARCODE_NUMBER":
            return {
                ...state,
                getBarcode: action.value,
            };

        case "CHANGEDBACODE":
            return {
                ...state,
                changebarcodeStatus: true,
            };

        case "CHANGEDBACODEFALSE":
            return {
                ...state,
                changebarcodeStatus: false,
            };

        case "LOADING_ADD_GRADE":
            return {
                ...state,
                loadingAddGrade: true,
                addGradeSuccess: false,
            };
        case "ADD_GRADE_SUCCESS":
            return {
                ...state,
                loadingAddGrade: false,
                addGradeSuccess: true,
            };
        case "CHANGE_GRADE_SUCCESS_STATUS":
            return {
                ...state,
                addGradeSuccess: false,
            };
        case "ADD_GRADE_UNSUCCESS":
            return {
                ...state,
                loadingAddGrade: false,
                addGradeSuccess: false,
            };

        case "GET_GRADE":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getGradedata: action.value.rows,
                    gradeDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getGradedata: action.value,
                };
            }

        case "UPDATE_GRADE_SUCCESS":
            return {
                ...state,
                updateGradeDataSuccess: true,
                loadingUpdateGrade: false,
            };

        case "CHANGE_UPDATE_GRADE_SUCCESS_STATUS":
            return {
                ...state,
                updateGradeDataSuccess: false,
            };

        case "LOADING_UPDATE_GREDE_STATUS":
            return {
                ...state,
                loadingUpdateGrade: true,
            };

        case "GET_ITEM_PRICE_HISTORY":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    getItempriceHistorydata: action.value.rows,
                    getItempriceHistorydataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    getItempriceHistorydata: action.value.rows,
                };
            }

        case "GET_ITEM_PRICE_HISTORY_FAILED":
            return {
                ...state,
                getItempriceHistorydata: null,
                getItempriceHistorydataPageSize: 0,
            };

        case "UPDATE_GRADE_DATA_UNSUCCES":
            return {
                ...state,
                updateGradeDataSuccess: false,
                loadingUpdateGrade: false,
            };

        case "ADD_DOC_TYPE_SUCCESS":
            return {
                ...state,
                AddDocTypeLoadingStatus: false,
                DocTypeStatus: true,
            };
        case "ADD_DOC_TYPE_LOADING":
            return {
                ...state,
                AddDocTypeLoadingStatus: true,
            };
        case "ADD_DOC_TYPE_FAIL":
            return {
                ...state,
                DocTypeStatus: false,
                AddDocTypeLoadingStatus: false,
            };
        case "CHANGE_DOC_TYPE_STATE":
            return {
                ...state,
                DocTypeStatus: false,
            };

        case "GET_DOC_TYPE_DATA":
            return {
                ...state,
                DocTypeData: action.value,
            };
        case "CHANGE_DOC_TYPE_UPDATE_STATE":
            return {
                ...state,
                DocTypeUpdateStatus: false,
            };
        case "DOC_TYPE_UPDATED":
            return {
                ...state,
                DocTypeUpdateStatus: true,
                DocTypeUpdateLoadingStatus: false,
            };
        case "UPDATE_DOC_TYPE_LOADING":
            return {
                ...state,
                DocTypeUpdateLoadingStatus: true,
            };
        case "UPDATE_DOC_TYPE_UNSUCCESS":
            return {
                ...state,
                DocTypeUpdateStatus: false,
                DocTypeUpdateLoadingStatus: false,
            };

        case "DOC_TYPE_DELETED":
            return {
                ...state,
                DocTypeData: state.DocTypeData.filter((DocType) => DocType.id !== action.value),
            };

        case "GET_MANUAL_STEP_DATA":
            return {
                ...state,
                ManualStepData: action.value,
            };
        case "GET_MANUAL_STEP_CODES":
            return {
                ...state,
                ManualStepCodes: action.value,
            };

        case "GET_ASSIGNED_RECORD_TYPE_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...state,
                    AssignedRecordTypeData: action.value.rows,
                    AssignedRecordTypeDataPageSize: action.value.pages,
                };
            } else {
                return {
                    ...state,
                    AssignedRecordTypeData: action.value,
                };
            }

        case "ADD_MANUAL_STEP_SUCCESS":
            return {
                ...state,
                ManualStepLoadingStatus: false,
                ManualStepAddStatus: true,
            };
        case "ADD_MANUAL_STEP_LOADING":
            return {
                ...state,
                ManualStepLoadingStatus: true,
            };
        case "ADD_MANUAL_STEP_FAIL":
            return {
                ...state,

                ManualStepLoadingStatus: false,
                ManualStepAddStatus: false,
            };
        case "CHANGE_MANUAL_STEP_STATE":
            return {
                ...state,
                ManualStepAddStatus: false,
            };

        case "MANUAL_STEP_UPDATED":
            return {
                ...state,
                loadingUpdateMAnaualStep: false,
                ManualStepUpdateStatus: true,
            };

        case "UPDATE_MANUAL_STEP_LOADING":
            return {
                ...state,
                loadingUpdateMAnaualStep: true,
            };

        case "CHANGE_MANUAL_STEP_UPDATE_STATE":
            return {
                ...state,
                ManualStepUpdateStatus: false,
            };

        case "UPDATE_MANUAL_STEP_UNSUCCESS":
            return {
                ...state,
                loadingUpdateMAnaualStep: false,
                ManualStepUpdateStatus: false,
            };

        case "GET_RAW_MQ_DATA":
            return {
                ...state,
                RawMqData: action.value,
            };

        case "RESET_RAW_MQ_DATA":
            return {
                ...state,
                RawMqData: [],
            };

        case "ADD_RAW_MQP_SUCCESS":
            return {
                ...state,
                RawMqLoadingStatus: false,
                RawMqAddStatus: true,
            };
        case "ADD_RAW_MQ_LOADING":
            return {
                ...state,
                RawMqLoadingStatus: true,
            };
        case "ADD_RAW_MQ_FAIL":
            return {
                ...state,

                RawMqLoadingStatus: false,
                RawMqAddStatus: false,
            };
        case "CHANGE_RAW_MQ_STATE":
            return {
                ...state,
                RawMqAddStatus: false,
            };

        case "RAW_MQ_UPDATED":
            return {
                ...state,
                loadingUpdateRawMq: false,
                RawMqUpdateStatus: true,
            };

        case "UPDATE_RAW_MQ_LOADING":
            return {
                ...state,
                loadingUpdateRawMq: true,
            };

        case "CHANGE_RAW_MQ_UPDATE_STATE":
            return {
                ...state,
                RawMqUpdateStatus: false,
            };

        case "UPDATE_RAW_MQ_UNSUCCESS":
            return {
                ...state,
                loadingUpdateRawMq: false,
                RawMqUpdateStatus: false,
            };

        case "GET_RAW_MI_DATA":
            return {
                ...state,
                RawMIData: action.value,
            };

        case "RESET_RAW_MI_DATA":
            return {
                ...state,
                RawMIData: [],
            };

        case "ADD_RAW_MI_SUCCESS":
            return {
                ...state,
                RawMILoadingStatus: false,
                RawMIAddStatus: true,
            };
        case "ADD_RAW_MI_LOADING":
            return {
                ...state,
                RawMILoadingStatus: true,
            };
        case "ADD_RAW_MI_FAIL":
            return {
                ...state,

                RawMILoadingStatus: false,
                RawMIAddStatus: false,
            };
        case "CHANGE_RAW_MI_STATE":
            return {
                ...state,
                RawMIAddStatus: false,
            };

        case "RAW_MI_UPDATED":
            return {
                ...state,
                loadingUpdateRawMI: false,
                RawMIUpdateStatus: true,
            };

        case "UPDATE_RAW_MI_LOADING":
            return {
                ...state,
                loadingUpdateRawMI: true,
            };

        case "CHANGE_RAW_MI_UPDATE_STATE":
            return {
                ...state,
                RawMIUpdateStatus: false,
            };

        case "UPDATE_RAW_MI_UNSUCCESS":
            return {
                ...state,
                loadingUpdateRawMI: false,
                RawMIUpdateStatus: false,
            };

        case "GET_WEIGHT_DATA":
            return {
                ...state,
                getWeightData: action.value,
            };

        case "GET_WIP_STEP_DATA":
            return {
                ...state,
                wipStepData: action.value,
            };

        case "RESET_WIP_STEP_DATA":
            return {
                ...state,
                wipStepData: null,
            };

        case "ADD_WIP_STEP_SUCCESS":
            return {
                ...state,
                WIPSTEPLoadingStatus: false,
                WIPStepAddStatus: true,
            };
        case "ADD_WIP_STEP_LOADING":
            return {
                ...state,
                WIPSTEPLoadingStatus: true,
            };
        case "ADD_WIP_STEP_FAIL":
            return {
                ...state,

                WIPSTEPLoadingStatus: false,
                WIPStepAddStatus: false,
            };
        case "CHANGE_WIP_STEP_STATE":
            return {
                ...state,
                WIPStepAddStatus: false,
            };

        case "WIP_STEP_UPDATED":
            return {
                ...state,
                loadingUpdateWIPStep: false,
                WIPStepUpdateStatus: true,
            };

        case "UPDATE_WIP_STEP_LOADING":
            return {
                ...state,
                loadingUpdateWIPStep: true,
            };

        case "CHANGE_WIP_STEP_UPDATE_STATE":
            return {
                ...state,
                WIPStepUpdateStatus: false,
            };

        case "UPDATE_WIP_STEP_UNSUCCESS":
            return {
                ...state,
                loadingUpdateWIPStep: false,
                WIPStepUpdateStatus: false,
            };

        case "WIP_STEP_SEQUENCE_UPDATED":
            return {
                ...state,
                loadingUpdateWIPStepSequence: false,
                WIPStepUpdateStatusSequence: true,
            };

        case "UPDATE_WIP_STEP_SEQUENCE_LOADING":
            return {
                ...state,
                loadingUpdateWIPStepSequence: true,
            };

        case "CHANGE_WIP_STEP_SEQUENCE_UPDATE_STATE":
            return {
                ...state,
                WIPStepUpdateStatusSequence: false,
            };

        case "UPDATE_WIP_STEP_SEQUENCE_UNSUCCESS":
            return {
                ...state,
                loadingUpdateWIPStepSequence: false,
                WIPStepUpdateStatusSequence: false,
            };

        case "GET_ITEM_STEP_DATA":
            return {
                ...state,
                ITEMStepData: action.value,
            };

        case "RESET_ITEM_STEP_DATA":
            return {
                ...state,
                ITEMStepData: null,
            };

        case "ADD_ITEM_STEP_SUCCESS":
            return {
                ...state,
                ITEMSTEPLoadingStatus: false,
                ITEMStepAddStatus: true,
            };
        case "ADD_ITEM_STEP_LOADING":
            return {
                ...state,
                ITEMSTEPLoadingStatus: true,
            };
        case "ADD_ITEM_STEP_FAIL":
            return {
                ...state,

                ITEMSTEPLoadingStatus: false,
                ITEMStepAddStatus: false,
            };
        case "CHANGE_ITEM_STEP_STATE":
            return {
                ...state,
                ITEMStepAddStatus: false,
            };

        case "ITEM_STEP_UPDATED":
            return {
                ...state,
                loadingUpdateITEMStep: false,
                ITEMStepUpdateStatus: true,
            };

        case "UPDATE_ITEM_STEP_LOADING":
            return {
                ...state,
                loadingUpdateITEMStep: true,
            };

        case "CHANGE_ITEM_STEP_UPDATE_STATE":
            return {
                ...state,
                ITEMStepUpdateStatus: false,
            };

        case "UPDATE_ITEM_STEP_UNSUCCESS":
            return {
                ...state,
                loadingUpdateITEMStep: false,
                ITEMStepUpdateStatus: false,
            };

        case "ITEM_STEP_SEQUENCE_UPDATED":
            return {
                ...state,
                loadingUpdateITEMStepSequence: false,
                ITEMStepUpdateStatusSequence: true,
            };

        case "UPDATE_ITEM_STEP_SEQUENCE_LOADING":
            return {
                ...state,
                loadingUpdateITEMStepSequence: true,
            };

        case "CHANGE_ITEM_STEP_SEQUENCE_UPDATE_STATE":
            return {
                ...state,
                ITEMtepUpdateStatusSequence: false,
            };

        case "UPDATE_ITEM_STEP_SEQUENCE_UNSUCCESS":
            return {
                ...state,
                loadingUpdateITEMStepSequence: false,
                ITEMStepUpdateStatusSequence: false,
            };

        case "GET_ITEMS_IN_SAME_PRODUCT":
            return {
                ...state,
                ItemData: action.value,
            };
        case "GET_UNIT_WEIGHT_OF_ITEM":
            return {
                ...state,
                ItemWeightUnit: action.value,
            };

        case "GET_ACCOUNT_TYPE_DATA":
            return {
                ...state,
                getAccountTypeData: action.value,
            };

        case "GET_ACCOUNT_REPORT_TYPE_DATA":
            return {
                ...state,
                getAccountReportTypeData: action.value,
            };

        case "GET_REPORT_TYPE_BY_ACCOUNT_DATA":
            return {
                ...state,
                getReportTypeByAccountData: action.value,
            };

        // purchase surcharge types
        case "GET_PURCHASE_SURCHARGE_TYPES":
            return {
                ...state,
                PurchaseSurchargeTypes: action.value,
            };

        case "ADD_PURCHASE_SURCHARGE_SUCCESS":
            return {
                ...state,
                AddPurchaseSurchargeType: true,
                LoadingPurchaseSurchargeTypeData: false,
            };

        case "CHANGE_ADD_PURCHASE_SURCHARGE_SUCCESS":
            return {
                ...state,
                AddPurchaseSurchargeType: false,
            };
        case "LOADING_PURCHASE_SURCHARGE_TYPE_DATA":
            return {
                ...state,
                LoadingPurchaseSurchargeTypeData: true,
            };

        case "ADD_PURCHASE_SURCHARGE_FAIL":
            return {
                ...state,
                AddPurchaseSurchargeType: false,
                LoadingUpdatePurchaseSurchargeButton: false,
            };

        case "LOADING_UPDATE_PURCHASE_SURCHARGE_BUTTON":
            return {
                ...state,
                LoadingPurchaseSurchargeData: true,
            };

        case "PURCHASE_SURCHARGE_DATA_UPDATED":
            return {
                ...state,
                PurchaseMethodUpdateStatus: true,
                LoadingUpdatePurchaseSurchargeButton: false,
            };

        case "PURCHASE_SURCHARGE_DATA_UNSUCCESS":
            return {
                ...state,
                PurchaseMethodUpdateStatus: false,
                LoadingUpdatePurchaseSurchargeButton: false,
            };

        case "GET_CUS_PRICE_ITEMS":
            return {
                ...newstate,
                ItemCusPriceItem: action.value,
            };

        case "GET_CUS_PRICE_ITEMS_STATUS":
            return {
                ...state,
                ItemCusPriceStatus: true,
            };

        case "CUS_PRICE_DATA_UPDATED":
            return {
                ...state,
                CusPricesUpdate: true,
            };
            
        case "CUS_PRICE_DATA_UPDATE_FAILED":
            return {
                ...state,
                CusPricesUpdate: false,
            };

        case "ASSIGN_PRICES_FOR_ITEM":
            return {
                ...newstate,
                itemAssignModal: action.value,
            };
        case "GET_SURCHARGE_DATA":
            return {
                ...state,
                SurchargeData: action.value,      
            };
        default:
            break;
    }
    return newstate;
};
export default reducer;
